define("due-dashboard/templates/components/v2/libraries/surveys/navbar/elements/add-element-modal", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          var child0 = (function () {
            var child0 = (function () {
              return {
                meta: {
                  "revision": "Ember@2.8.3",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 20,
                      "column": 16
                    },
                    "end": {
                      "line": 29,
                      "column": 16
                    }
                  },
                  "moduleName": "due-dashboard/templates/components/v2/libraries/surveys/navbar/elements/add-element-modal.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                  ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("div");
                  dom.setAttribute(el1, "class", "option-element-container parent-container");
                  var el2 = dom.createTextNode("\n                    ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("div");
                  dom.setAttribute(el2, "class", "left-part-container");
                  var el3 = dom.createTextNode("\n                      ");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createElement("div");
                  dom.setAttribute(el3, "class", "back-arrow-container");
                  var el4 = dom.createTextNode("\n                        ");
                  dom.appendChild(el3, el4);
                  var el4 = dom.createElement("i");
                  dom.setAttribute(el4, "class", "tio-caret_left");
                  dom.appendChild(el3, el4);
                  var el4 = dom.createTextNode("\n                      ");
                  dom.appendChild(el3, el4);
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode("\n                      ");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createElement("span");
                  dom.setAttribute(el3, "class", "due-typo-default subdued");
                  var el4 = dom.createComment("");
                  dom.appendChild(el3, el4);
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode("\n                    ");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                  ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element11 = dom.childAt(fragment, [1]);
                  var morphs = new Array(2);
                  morphs[0] = dom.createAttrMorph(element11, 'onclick');
                  morphs[1] = dom.createMorphAt(dom.childAt(element11, [1, 3]), 0, 0);
                  return morphs;
                },
                statements: [["attribute", "onclick", ["subexpr", "action", ["goBackNavigation", "content"], [], ["loc", [null, [null, null], [21, 120]]], 0, 0], 0, 0, 0, 0], ["inline", "t", ["words.back"], [], ["loc", [null, [26, 61], [26, 79]]], 0, 0]],
                locals: [],
                templates: []
              };
            })();
            var child1 = (function () {
              var child0 = (function () {
                return {
                  meta: {
                    "revision": "Ember@2.8.3",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 31,
                        "column": 18
                      },
                      "end": {
                        "line": 35,
                        "column": 18
                      }
                    },
                    "moduleName": "due-dashboard/templates/components/v2/libraries/surveys/navbar/elements/add-element-modal.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                    ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("div");
                    dom.setAttribute(el1, "class", "title-element-container");
                    var el2 = dom.createTextNode("\n                      ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createElement("span");
                    dom.setAttribute(el2, "class", "due-typo-h6 subdued bold");
                    var el3 = dom.createComment("");
                    dom.appendChild(el2, el3);
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                    ");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1]), 0, 0);
                    return morphs;
                  },
                  statements: [["content", "element.text", ["loc", [null, [33, 61], [33, 77]]], 0, 0, 0, 0]],
                  locals: [],
                  templates: []
                };
              })();
              var child1 = (function () {
                var child0 = (function () {
                  return {
                    meta: {
                      "revision": "Ember@2.8.3",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 39,
                          "column": 26
                        },
                        "end": {
                          "line": 41,
                          "column": 26
                        }
                      },
                      "moduleName": "due-dashboard/templates/components/v2/libraries/surveys/navbar/elements/add-element-modal.hbs"
                    },
                    isEmpty: false,
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("                            ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("\n");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                      return morphs;
                    },
                    statements: [["inline", "component", [["get", "element.icon", ["loc", [null, [40, 40], [40, 52]]], 0, 0, 0, 0]], [], ["loc", [null, [40, 28], [40, 54]]], 0, 0]],
                    locals: [],
                    templates: []
                  };
                })();
                var child1 = (function () {
                  return {
                    meta: {
                      "revision": "Ember@2.8.3",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 41,
                          "column": 26
                        },
                        "end": {
                          "line": 43,
                          "column": 26
                        }
                      },
                      "moduleName": "due-dashboard/templates/components/v2/libraries/surveys/navbar/elements/add-element-modal.hbs"
                    },
                    isEmpty: false,
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("                            ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createElement("i");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("\n");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var element7 = dom.childAt(fragment, [1]);
                      var morphs = new Array(2);
                      morphs[0] = dom.createAttrMorph(element7, 'class');
                      morphs[1] = dom.createAttrMorph(element7, 'style');
                      return morphs;
                    },
                    statements: [["attribute", "class", ["concat", ["icon ", ["get", "element.icon", ["loc", [null, [42, 45], [42, 57]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "style", ["concat", ["color: ", ["get", "element.iconColor", ["loc", [null, [42, 77], [42, 94]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0]],
                    locals: [],
                    templates: []
                  };
                })();
                var child2 = (function () {
                  return {
                    meta: {
                      "revision": "Ember@2.8.3",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 47,
                          "column": 22
                        },
                        "end": {
                          "line": 51,
                          "column": 22
                        }
                      },
                      "moduleName": "due-dashboard/templates/components/v2/libraries/surveys/navbar/elements/add-element-modal.hbs"
                    },
                    isEmpty: false,
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("                        ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createElement("div");
                      dom.setAttribute(el1, "class", "arrow-container");
                      var el2 = dom.createTextNode("\n                          ");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createElement("i");
                      dom.setAttribute(el2, "class", "tio-caret_right");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createTextNode("\n                        ");
                      dom.appendChild(el1, el2);
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("\n");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes() {
                      return [];
                    },
                    statements: [],
                    locals: [],
                    templates: []
                  };
                })();
                return {
                  meta: {
                    "revision": "Ember@2.8.3",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 35,
                        "column": 18
                      },
                      "end": {
                        "line": 53,
                        "column": 18
                      }
                    },
                    "moduleName": "due-dashboard/templates/components/v2/libraries/surveys/navbar/elements/add-element-modal.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                    ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("div");
                    dom.setAttribute(el1, "class", "option-element-container");
                    var el2 = dom.createTextNode("\n                      ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createElement("div");
                    dom.setAttribute(el2, "class", "left-part-container");
                    var el3 = dom.createTextNode("\n                        ");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createElement("div");
                    dom.setAttribute(el3, "class", "icon-container");
                    var el4 = dom.createTextNode("\n");
                    dom.appendChild(el3, el4);
                    var el4 = dom.createComment("");
                    dom.appendChild(el3, el4);
                    var el4 = dom.createTextNode("                        ");
                    dom.appendChild(el3, el4);
                    dom.appendChild(el2, el3);
                    var el3 = dom.createTextNode("\n                        ");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createElement("span");
                    dom.setAttribute(el3, "class", "due-typo-default bold");
                    var el4 = dom.createComment("");
                    dom.appendChild(el3, el4);
                    dom.appendChild(el2, el3);
                    var el3 = dom.createTextNode("\n                      ");
                    dom.appendChild(el2, el3);
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("                    ");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var element8 = dom.childAt(fragment, [1]);
                    var element9 = dom.childAt(element8, [1]);
                    var element10 = dom.childAt(element9, [1]);
                    var morphs = new Array(6);
                    morphs[0] = dom.createAttrMorph(element8, 'onclick');
                    morphs[1] = dom.createAttrMorph(element8, 'onmouseover');
                    morphs[2] = dom.createAttrMorph(element10, 'style');
                    morphs[3] = dom.createMorphAt(element10, 1, 1);
                    morphs[4] = dom.createMorphAt(dom.childAt(element9, [3]), 0, 0);
                    morphs[5] = dom.createMorphAt(element8, 3, 3);
                    return morphs;
                  },
                  statements: [["attribute", "onclick", ["subexpr", "action", ["selectOption", ["get", "element", ["loc", [null, [36, 90], [36, 97]]], 0, 0, 0, 0], "content", ["get", "elementIdx", ["loc", [null, [36, 108], [36, 118]]], 0, 0, 0, 0]], [], ["loc", [null, [null, null], [36, 120]]], 0, 0], 0, 0, 0, 0], ["attribute", "onmouseover", ["subexpr", "action", ["setHoverElement", ["get", "element", ["loc", [null, [36, 160], [36, 167]]], 0, 0, 0, 0]], [], ["loc", [null, [null, null], [36, 169]]], 0, 0], 0, 0, 0, 0], ["attribute", "style", ["concat", ["background-color: ", ["get", "element.backgroundColor", ["loc", [null, [38, 79], [38, 102]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["block", "if", [["subexpr", "is-component", [["get", "element.icon", ["loc", [null, [39, 46], [39, 58]]], 0, 0, 0, 0]], [], ["loc", [null, [39, 32], [39, 59]]], 0, 0]], [], 0, 1, ["loc", [null, [39, 26], [43, 33]]]], ["content", "element.text", ["loc", [null, [45, 60], [45, 76]]], 0, 0, 0, 0], ["block", "if", [["subexpr", "gte", [["get", "element.options.length", ["loc", [null, [47, 33], [47, 55]]], 0, 0, 0, 0], 0], [], ["loc", [null, [47, 28], [47, 58]]], 0, 0]], [], 2, null, ["loc", [null, [47, 22], [51, 29]]]]],
                  locals: [],
                  templates: [child0, child1, child2]
                };
              })();
              return {
                meta: {
                  "revision": "Ember@2.8.3",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 30,
                      "column": 16
                    },
                    "end": {
                      "line": 54,
                      "column": 16
                    }
                  },
                  "moduleName": "due-dashboard/templates/components/v2/libraries/surveys/navbar/elements/add-element-modal.hbs"
                },
                isEmpty: false,
                arity: 2,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [["block", "if", [["subexpr", "eq", [["get", "element.type", ["loc", [null, [31, 28], [31, 40]]], 0, 0, 0, 0], "title"], [], ["loc", [null, [31, 24], [31, 49]]], 0, 0]], [], 0, 1, ["loc", [null, [31, 18], [53, 25]]]]],
                locals: ["element", "elementIdx"],
                templates: [child0, child1]
              };
            })();
            var child2 = (function () {
              var child0 = (function () {
                return {
                  meta: {
                    "revision": "Ember@2.8.3",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 55,
                        "column": 18
                      },
                      "end": {
                        "line": 61,
                        "column": 18
                      }
                    },
                    "moduleName": "due-dashboard/templates/components/v2/libraries/surveys/navbar/elements/add-element-modal.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                    ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("div");
                    dom.setAttribute(el1, "class", "option-element-container-empty");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n                    ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("div");
                    dom.setAttribute(el1, "class", "option-element-container-empty");
                    var el2 = dom.createTextNode("\n                        ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createElement("div");
                    dom.setAttribute(el2, "class", "loader");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                    ");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n                    ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("div");
                    dom.setAttribute(el1, "class", "option-element-container-empty");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes() {
                    return [];
                  },
                  statements: [],
                  locals: [],
                  templates: []
                };
              })();
              return {
                meta: {
                  "revision": "Ember@2.8.3",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 54,
                      "column": 16
                    },
                    "end": {
                      "line": 62,
                      "column": 16
                    }
                  },
                  "moduleName": "due-dashboard/templates/components/v2/libraries/surveys/navbar/elements/add-element-modal.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [["block", "if", [["subexpr", "and", [["subexpr", "eq", [["get", "currentContentParent.key", ["loc", [null, [55, 33], [55, 57]]], 0, 0, 0, 0], "rating"], [], ["loc", [null, [55, 29], [55, 67]]], 0, 0], ["subexpr", "eq", [["get", "currentContentParent.options.length", ["loc", [null, [55, 72], [55, 107]]], 0, 0, 0, 0], 0], [], ["loc", [null, [55, 68], [55, 110]]], 0, 0]], [], ["loc", [null, [55, 24], [55, 111]]], 0, 0]], [], 0, null, ["loc", [null, [55, 18], [61, 25]]]]],
                locals: [],
                templates: [child0]
              };
            })();
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 15,
                    "column": 14
                  },
                  "end": {
                    "line": 63,
                    "column": 14
                  }
                },
                "moduleName": "due-dashboard/templates/components/v2/libraries/surveys/navbar/elements/add-element-modal.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(2);
                morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                morphs[1] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [["block", "if", [["get", "currentContentParent", ["loc", [null, [20, 22], [20, 42]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [20, 16], [29, 23]]]], ["block", "each", [["get", "currentContentNavigation", ["loc", [null, [30, 24], [30, 48]]], 0, 0, 0, 0]], [], 1, 2, ["loc", [null, [30, 16], [62, 25]]]]],
              locals: [],
              templates: [child0, child1, child2]
            };
          })();
          var child1 = (function () {
            var child0 = (function () {
              return {
                meta: {
                  "revision": "Ember@2.8.3",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 69,
                      "column": 16
                    },
                    "end": {
                      "line": 78,
                      "column": 16
                    }
                  },
                  "moduleName": "due-dashboard/templates/components/v2/libraries/surveys/navbar/elements/add-element-modal.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                  ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("div");
                  dom.setAttribute(el1, "class", "option-element-container parent-container");
                  var el2 = dom.createTextNode("\n                    ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("div");
                  dom.setAttribute(el2, "class", "left-part-container");
                  var el3 = dom.createTextNode("\n                      ");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createElement("div");
                  dom.setAttribute(el3, "class", "back-arrow-container");
                  var el4 = dom.createTextNode("\n                        ");
                  dom.appendChild(el3, el4);
                  var el4 = dom.createElement("i");
                  dom.setAttribute(el4, "class", "tio-caret_left");
                  dom.appendChild(el3, el4);
                  var el4 = dom.createTextNode("\n                      ");
                  dom.appendChild(el3, el4);
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode("\n                      ");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createElement("span");
                  dom.setAttribute(el3, "class", "due-typo-default subdued");
                  var el4 = dom.createComment("");
                  dom.appendChild(el3, el4);
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode("\n                    ");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                  ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element6 = dom.childAt(fragment, [1]);
                  var morphs = new Array(2);
                  morphs[0] = dom.createAttrMorph(element6, 'onclick');
                  morphs[1] = dom.createMorphAt(dom.childAt(element6, [1, 3]), 0, 0);
                  return morphs;
                },
                statements: [["attribute", "onclick", ["subexpr", "action", ["goBackNavigation", "logical"], [], ["loc", [null, [null, null], [70, 120]]], 0, 0], 0, 0, 0, 0], ["inline", "t", ["words.back"], [], ["loc", [null, [75, 61], [75, 79]]], 0, 0]],
                locals: [],
                templates: []
              };
            })();
            var child1 = (function () {
              var child0 = (function () {
                var child0 = (function () {
                  return {
                    meta: {
                      "revision": "Ember@2.8.3",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 94,
                          "column": 24
                        },
                        "end": {
                          "line": 100,
                          "column": 24
                        }
                      },
                      "moduleName": "due-dashboard/templates/components/v2/libraries/surveys/navbar/elements/add-element-modal.hbs"
                    },
                    isEmpty: false,
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("                          ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createElement("div");
                      dom.setAttribute(el1, "class", "right-part-container");
                      var el2 = dom.createTextNode("\n                            ");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createElement("div");
                      dom.setAttribute(el2, "class", "arrow-container");
                      var el3 = dom.createTextNode("\n                              ");
                      dom.appendChild(el2, el3);
                      var el3 = dom.createElement("i");
                      dom.setAttribute(el3, "class", "tio-caret_right");
                      dom.appendChild(el2, el3);
                      var el3 = dom.createTextNode("\n                            ");
                      dom.appendChild(el2, el3);
                      dom.appendChild(el1, el2);
                      var el2 = dom.createTextNode("\n                          ");
                      dom.appendChild(el1, el2);
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("\n");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes() {
                      return [];
                    },
                    statements: [],
                    locals: [],
                    templates: []
                  };
                })();
                return {
                  meta: {
                    "revision": "Ember@2.8.3",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 81,
                        "column": 20
                      },
                      "end": {
                        "line": 102,
                        "column": 20
                      }
                    },
                    "moduleName": "due-dashboard/templates/components/v2/libraries/surveys/navbar/elements/add-element-modal.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                      ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("div");
                    dom.setAttribute(el1, "class", "logical-option-element-container");
                    var el2 = dom.createTextNode("\n                        ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createElement("div");
                    dom.setAttribute(el2, "class", "left-part-container");
                    var el3 = dom.createTextNode("\n                          ");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createElement("div");
                    dom.setAttribute(el3, "class", "card-header-container");
                    var el4 = dom.createTextNode("\n                            ");
                    dom.appendChild(el3, el4);
                    var el4 = dom.createElement("div");
                    dom.setAttribute(el4, "class", "icon-container");
                    var el5 = dom.createTextNode("\n                              ");
                    dom.appendChild(el4, el5);
                    var el5 = dom.createElement("i");
                    dom.appendChild(el4, el5);
                    var el5 = dom.createTextNode("\n                            ");
                    dom.appendChild(el4, el5);
                    dom.appendChild(el3, el4);
                    var el4 = dom.createTextNode("\n                            ");
                    dom.appendChild(el3, el4);
                    var el4 = dom.createElement("span");
                    dom.setAttribute(el4, "class", "due-typo-default bold");
                    var el5 = dom.createComment("");
                    dom.appendChild(el4, el5);
                    dom.appendChild(el3, el4);
                    var el4 = dom.createTextNode("\n                          ");
                    dom.appendChild(el3, el4);
                    dom.appendChild(el2, el3);
                    var el3 = dom.createTextNode("\n                          ");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createElement("div");
                    dom.setAttribute(el3, "class", "more-text-container");
                    var el4 = dom.createTextNode("\n                            ");
                    dom.appendChild(el3, el4);
                    var el4 = dom.createElement("span");
                    dom.setAttribute(el4, "class", "due-typo-default subdued");
                    var el5 = dom.createComment("");
                    dom.appendChild(el4, el5);
                    dom.appendChild(el3, el4);
                    var el4 = dom.createTextNode("\n                          ");
                    dom.appendChild(el3, el4);
                    dom.appendChild(el2, el3);
                    var el3 = dom.createTextNode("\n                        ");
                    dom.appendChild(el2, el3);
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("                      ");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var element1 = dom.childAt(fragment, [1]);
                    var element2 = dom.childAt(element1, [1]);
                    var element3 = dom.childAt(element2, [1]);
                    var element4 = dom.childAt(element3, [1]);
                    var element5 = dom.childAt(element4, [1]);
                    var morphs = new Array(8);
                    morphs[0] = dom.createAttrMorph(element1, 'onclick');
                    morphs[1] = dom.createAttrMorph(element1, 'onmouseover');
                    morphs[2] = dom.createAttrMorph(element4, 'style');
                    morphs[3] = dom.createAttrMorph(element5, 'class');
                    morphs[4] = dom.createAttrMorph(element5, 'style');
                    morphs[5] = dom.createMorphAt(dom.childAt(element3, [3]), 0, 0);
                    morphs[6] = dom.createMorphAt(dom.childAt(element2, [3, 1]), 0, 0);
                    morphs[7] = dom.createMorphAt(element1, 3, 3);
                    return morphs;
                  },
                  statements: [["attribute", "onclick", ["subexpr", "action", ["selectOption", ["get", "element", ["loc", [null, [82, 100], [82, 107]]], 0, 0, 0, 0], "logical", ["get", "elementIdx", ["loc", [null, [82, 118], [82, 128]]], 0, 0, 0, 0]], [], ["loc", [null, [null, null], [82, 130]]], 0, 0], 0, 0, 0, 0], ["attribute", "onmouseover", ["subexpr", "action", ["setHoverElement", ["get", "element", ["loc", [null, [82, 170], [82, 177]]], 0, 0, 0, 0]], [], ["loc", [null, [null, null], [82, 179]]], 0, 0], 0, 0, 0, 0], ["attribute", "style", ["concat", ["background-color: ", ["get", "element.backgroundColor", ["loc", [null, [85, 83], [85, 106]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "class", ["concat", ["icon ", ["get", "element.icon", ["loc", [null, [86, 47], [86, 59]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "style", ["concat", ["color: ", ["get", "element.iconColor", ["loc", [null, [86, 79], [86, 96]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["content", "element.text", ["loc", [null, [88, 64], [88, 80]]], 0, 0, 0, 0], ["content", "element.moreInformationsText", ["loc", [null, [91, 67], [91, 99]]], 0, 0, 0, 0], ["block", "if", [["subexpr", "gte", [["get", "element.options.length", ["loc", [null, [94, 35], [94, 57]]], 0, 0, 0, 0], 0], [], ["loc", [null, [94, 30], [94, 60]]], 0, 0]], [], 0, null, ["loc", [null, [94, 24], [100, 31]]]]],
                  locals: [],
                  templates: [child0]
                };
              })();
              return {
                meta: {
                  "revision": "Ember@2.8.3",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 80,
                      "column": 18
                    },
                    "end": {
                      "line": 103,
                      "column": 18
                    }
                  },
                  "moduleName": "due-dashboard/templates/components/v2/libraries/surveys/navbar/elements/add-element-modal.hbs"
                },
                isEmpty: false,
                arity: 2,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [["block", "unless", [["subexpr", "eq", [["get", "element.type", ["loc", [null, [81, 34], [81, 46]]], 0, 0, 0, 0], "title"], [], ["loc", [null, [81, 30], [81, 55]]], 0, 0]], [], 0, null, ["loc", [null, [81, 20], [102, 31]]]]],
                locals: ["element", "elementIdx"],
                templates: [child0]
              };
            })();
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 64,
                    "column": 14
                  },
                  "end": {
                    "line": 105,
                    "column": 14
                  }
                },
                "moduleName": "due-dashboard/templates/components/v2/libraries/surveys/navbar/elements/add-element-modal.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("                ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1, "class", "logical-elements-container");
                var el2 = dom.createTextNode("\n");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("                ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(2);
                morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                morphs[1] = dom.createMorphAt(dom.childAt(fragment, [2]), 1, 1);
                dom.insertBoundary(fragment, 0);
                return morphs;
              },
              statements: [["block", "if", [["get", "currentLogicalParent", ["loc", [null, [69, 22], [69, 42]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [69, 16], [78, 23]]]], ["block", "each", [["get", "currentLogicalNavigation", ["loc", [null, [80, 26], [80, 50]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [80, 18], [103, 27]]]]],
              locals: [],
              templates: [child0, child1]
            };
          })();
          var child2 = (function () {
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 111,
                    "column": 18
                  },
                  "end": {
                    "line": 115,
                    "column": 18
                  }
                },
                "moduleName": "due-dashboard/templates/components/v2/libraries/surveys/navbar/elements/add-element-modal.hbs"
              },
              isEmpty: false,
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                    ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                var el2 = dom.createTextNode("\n                      ");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                    ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element0 = dom.childAt(fragment, [1]);
                var morphs = new Array(2);
                morphs[0] = dom.createAttrMorph(element0, 'class');
                morphs[1] = dom.createMorphAt(element0, 1, 1);
                return morphs;
              },
              statements: [["attribute", "class", ["concat", ["preview-", ["get", "previewElement.kind", ["loc", [null, [112, 42], [112, 61]]], 0, 0, 0, 0], " svg"], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["inline", "component", [["get", "previewElement.component", ["loc", [null, [113, 34], [113, 58]]], 0, 0, 0, 0]], [], ["loc", [null, [113, 22], [113, 60]]], 0, 0]],
              locals: ["previewElement"],
              templates: []
            };
          })();
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 10,
                  "column": 10
                },
                "end": {
                  "line": 119,
                  "column": 10
                }
              },
              "moduleName": "due-dashboard/templates/components/v2/libraries/surveys/navbar/elements/add-element-modal.hbs"
            },
            isEmpty: false,
            arity: 2,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "tab-children-container");
              var el2 = dom.createTextNode("\n");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("              ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "class", "preview-element-container");
              var el3 = dom.createTextNode("\n                ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("div");
              dom.setAttribute(el3, "class", "cancel-ross-container");
              var el4 = dom.createTextNode("\n                  ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("i");
              dom.setAttribute(el4, "class", "tio-clear");
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("div");
              dom.setAttribute(el3, "class", "preview");
              var el4 = dom.createTextNode("\n");
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("                ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n              ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n            ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element12 = dom.childAt(fragment, [1]);
              var element13 = dom.childAt(element12, [4]);
              var element14 = dom.childAt(element13, [1]);
              var morphs = new Array(4);
              morphs[0] = dom.createMorphAt(element12, 1, 1);
              morphs[1] = dom.createMorphAt(element12, 2, 2);
              morphs[2] = dom.createAttrMorph(element14, 'onclick');
              morphs[3] = dom.createMorphAt(dom.childAt(element13, [3]), 1, 1);
              return morphs;
            },
            statements: [["block", "component", ["v2/tabs-navigation/-tab-navigation-item"], ["index", 0, "currentItem", ["subexpr", "@mut", [["get", "selectedTab", ["loc", [null, [17, 28], [17, 39]]], 0, 0, 0, 0]], [], [], 0, 0], "onSelect", ["subexpr", "@mut", [["get", "updateSelectedTab", ["loc", [null, [18, 25], [18, 42]]], 0, 0, 0, 0]], [], [], 0, 0]], 0, null, ["loc", [null, [15, 14], [63, 28]]]], ["block", "component", ["v2/tabs-navigation/-tab-navigation-item"], ["index", 1, "currentItem", ["subexpr", "@mut", [["get", "selectedTab", ["loc", [null, [66, 28], [66, 39]]], 0, 0, 0, 0]], [], [], 0, 0], "onSelect", ["subexpr", "@mut", [["get", "updateSelectedTab", ["loc", [null, [67, 25], [67, 42]]], 0, 0, 0, 0]], [], [], 0, 0]], 1, null, ["loc", [null, [64, 14], [105, 28]]]], ["attribute", "onclick", ["subexpr", "action", ["closeModal"], [], ["loc", [null, [null, null], [107, 82]]], 0, 0], 0, 0, 0, 0], ["block", "each", [["get", "previewElements", ["loc", [null, [111, 26], [111, 41]]], 0, 0, 0, 0]], [], 2, null, ["loc", [null, [111, 18], [115, 27]]]]],
            locals: ["updateSelectedTab", "selectedTab"],
            templates: [child0, child1, child2]
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 2,
                "column": 2
              },
              "end": {
                "line": 123,
                "column": 2
              }
            },
            "moduleName": "due-dashboard/templates/components/v2/libraries/surveys/navbar/elements/add-element-modal.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "modal-container");
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3, "class", "tab-options-container");
            var el4 = dom.createTextNode("\n");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("        ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n      ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element15 = dom.childAt(fragment, [1, 1]);
            var morphs = new Array(2);
            morphs[0] = dom.createAttrMorph(element15, 'class');
            morphs[1] = dom.createMorphAt(dom.childAt(element15, [1]), 1, 1);
            return morphs;
          },
          statements: [["attribute", "class", ["concat", ["options-selection-container ", ["get", "hoveredItem", ["loc", [null, [8, 48], [8, 59]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["block", "component", ["v2/tabs-navigation/-tabs-navigation"], ["options", ["subexpr", "@mut", [["get", "tabOptions", ["loc", [null, [11, 20], [11, 30]]], 0, 0, 0, 0]], [], [], 0, 0]], 0, null, ["loc", [null, [10, 10], [119, 24]]]]],
          locals: [],
          templates: [child0]
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 124,
              "column": 0
            }
          },
          "moduleName": "due-dashboard/templates/components/v2/libraries/surveys/navbar/elements/add-element-modal.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "modal-dialog", [], ["close", "closeModal", "container-class", "add-survey-element-modal", "translucentOverlay", true], 0, null, ["loc", [null, [2, 2], [123, 19]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 125,
            "column": 0
          }
        },
        "moduleName": "due-dashboard/templates/components/v2/libraries/surveys/navbar/elements/add-element-modal.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "if", [["get", "isOpen", ["loc", [null, [1, 6], [1, 12]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [1, 0], [124, 7]]]]],
      locals: [],
      templates: [child0]
    };
  })());
});