define('due-dashboard/components/v2/libraries/surveys/navbar/elements/-long-text-input', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var set = _ember['default'].set;
  exports['default'] = Component.extend({
    classNames: ['surveys-library-navbar-content-element-long-text-input'],
    focus: false,
    cachedValues: {},
    foundMainCommentStep: null,
    foundMainCommentElement: null,

    init: function init() {
      var _this = this;

      var content = this.get('object.content');
      Object.keys(content).map(function (key) {
        return _this.cachedValues[key] = content[key];
      });
      this._super.apply(this, arguments);
    },

    hasDefaultValueObj: computed('object.content.default_value', function () {
      var content = this.get('object.content');

      return content.default_value != null;
    }),

    _mainCommentAlreadyExists: function _mainCommentAlreadyExists() {
      var _this2 = this;

      var steps = this.get('steps');
      var selectedStepIdx = this.get('selectedStepIndex');
      var selectedElementIndex = this.get('selectedElementIndex');
      var found = false;

      steps.forEach(function (step, stepIdx) {
        step.survey_elements_attributes.forEach(function (element, elementIdx) {
          if (element.kind === 'long_text_input' && (stepIdx !== selectedStepIdx || elementIdx !== selectedElementIndex) && element.content.main_comment) {
            found = true;
            _this2.set('foundMainCommentStep', stepIdx);
            _this2.set('foundMainCommentElement', elementIdx);
          }
        });
      });
      return found;
    },

    actions: {
      onChangeContentField: function onChangeContentField() {
        var saveCallback = this.get('contentUpdateCallback');
        var content = this.get('object.content');

        if (saveCallback) {
          saveCallback(content);
        }
      },

      toggleContentField: function toggleContentField(field) {
        var content = this.get('object.content');

        if (field === 'main_comment' && this._mainCommentAlreadyExists()) {
          this.set('newMainCommentModalIsOpen', true);
        } else {
          set(content, field, !content[field]);
          this.send('onChangeContentField');
        }
      },

      updateContentField: function updateContentField(field, value) {
        var content = this.get('object.content');
        var focus = this.get('focus');

        if (!focus && value !== -1) this.set('focus', true);
        if (value !== -1) {
          var valueChanged = !this.cachedValues[field] || this.cachedValues[field] && !(this.cachedValues[field] === value);
          this.cachedValues[field] = value;
          set(content, field, value);
          if (valueChanged) this.send('onChangeContentField');
        }
        if (value === -1 && this.get('focus')) this.set('focus', false);
      },

      updateDefaultValue: function updateDefaultValue() {
        var content = this.get('object.content');
        var hasDefaultValueObj = this.get('hasDefaultValueObj');
        var defaultValue = { from: 'url_param', url_param: '' };

        if (hasDefaultValueObj) {
          set(content, 'default_value', null);
        } else {
          set(content, 'default_value', defaultValue);
        }

        this.send('onChangeContentField');
      },

      closeNewMainCommentModal: function closeNewMainCommentModal() {
        this.set('newMainCommentModalIsOpen', false);
      },

      cancelMainCommentChange: function cancelMainCommentChange() {
        this.send('closeNewMainCommentModal');
      },

      applyMainCommentChange: function applyMainCommentChange() {
        var steps = this.get('steps');
        var selectedStepIndex = this.get('selectedStepIndex');
        var selectedElementIndex = this.get('selectedElementIndex');
        var foundMainCommentStep = this.get('foundMainCommentStep');
        var foundMainCommentElement = this.get('foundMainCommentElement');
        var saveChanges = this.get('saveChanges');

        steps[foundMainCommentStep].survey_elements_attributes[foundMainCommentElement].content.main_comment = false;
        steps[selectedStepIndex].survey_elements_attributes[selectedElementIndex].content = this.get('object.content');
        set(steps[selectedStepIndex].survey_elements_attributes, selectedElementIndex + '.content.main_comment', true);
        saveChanges(steps);

        this.send('closeNewMainCommentModal');
        this.set('foundMainCommentStep', null);
        this.set('foundMainCommentElement', null);
      }
    }
  });
});
/**
 * @author Antoine Yvroud <antoine@diduenjoy.com>
 * @summary {{v2/libraries/surveys/navbar/elements/-short-text-input}} short text input element config component
 * @figma https://www.figma.com/file/oVZcAHwvRm3G31IY8K6PK9/Dashboard----Survey-Builder-%5BWIP%5D?type=design&node-id=2888-44430&t=Lr5wZmZtcPOSO3A0-0
 */