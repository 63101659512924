define('due-dashboard/components/period-selector', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  exports['default'] = Component.extend({

    selectedPeriod: undefined,

    PERIOD_ENUM: [{ key: 'previous_period' }, { key: 'previous_year' }],

    // Not available now
    // { key: 'year_to_date' },
    // { key: 'full_last_year' },
    selectedPeriodComputed: computed('selectedPeriod', function () {
      return this.get('selectedPeriod') || this.get('PERIOD_ENUM')[0];
    }),

    actions: {

      changePeriod: function changePeriod(period) {
        this.set('selectedPeriod', period);
        if (this.get('periodPicked')) {
          this.get('periodPicked')(period.key);
        }
      }
    }
  });
});