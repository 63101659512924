define('due-dashboard/components/navbar-title', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  exports['default'] = Component.extend({
    tagName: 'li',
    classNames: ['navbar-title'],
    classNamesBindings: ['hidden', 'data-content'],
    currentLanguage: computed.alias('currentAccount.content.language.tag'),
    hidden: false,

    computedText: computed('text', function () {
      var textString = this.get('text').string || this.get('text');
      if (typeof textString === 'string') {
        return textString.startsWith('menu.') ? this.get('i18n').t(textString) : textString;
      } else {
        var text = this.get('text');
        return text[this.get('currentLanguage')] || text[this.get('currentAccount.content.company.language.tag')];
      }
    })
  });
});