define('due-dashboard/components/due-table/elements/-variation-duration-up', ['exports', 'ember', 'due-dashboard/helpers/compute-duration'], function (exports, _ember, _dueDashboardHelpersComputeDuration) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var htmlSafe = _ember['default'].String.htmlSafe;
  exports['default'] = Component.extend({
    tagName: 'div',
    classNames: ['report-array-number-trend', 'variation-duration-up'],

    upColor: 'green',

    displayGreen: false,
    displayRed: false,

    compValue: computed('value', function () {
      var value = this.get('value.variation');
      var sign = null;
      if (value == undefined) {
        return '-';
      } else if (value > 0) {
        sign = '+';
        this.get('upColor') == 'green' ? this.set('displayGreen', true) : this.set('displayRed', true);
      } else if (value < 0) {
        sign = '-';
        this.get('upColor') == 'green' ? this.set('displayRed', true) : this.set('displayGreen', true);
      }
      return '' + sign + _dueDashboardHelpersComputeDuration['default'].compute(this.get('i18n'), Math.abs(value));
    })

  });
});