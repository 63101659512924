define('due-dashboard/components/due-table/due-table-row', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var getOwner = _ember['default'].getOwner;
  exports['default'] = Component.extend({
    classNameBindings: ['hovered'],

    clickableLine: computed('lineElems', function () {
      return this.get('lineElems').subLines != undefined;
    }),

    displaySublines: computed('showLineComputed.lines', function () {
      var id = this.get('lineElems.id');
      var showLineComputed = this.get('showLineComputed.lines');
      return id && showLineComputed && showLineComputed[id];
    }),

    compPadding: computed('', function () {
      return this.get('lineElems.depth') * 16;
    }),

    nestedElementIndent: computed('', function () {
      if (this.get('compPadding') == 0 || this.get('clickableLine')) {
        return undefined;
      }
      // To be aligned with other elements, couldn't respect the 8 multiple value
      return 'padding-left: 33px;';
    }),

    hovered: computed('lineElems.id', 'hoveredObjectId', function () {
      return this.get('lineElems.id') === this.get('hoveredObjectId');
    }),

    firstLabelIndex: computed('lineElems.data.[]', function () {
      return this.get('lineElems.data').findIndex(function (e) {
        return e.type === 'label-with-sub';
      });
    }),

    _sendHoverEvent: function _sendHoverEvent(action) {
      var id = this.get('lineElems.id');

      if (id) {
        this.get(action)(id);
      }
    },

    actions: {
      mouseOver: function mouseOver() {
        this._sendHoverEvent('mouse-over');
      },

      mouseOut: function mouseOut() {
        this._sendHoverEvent('mouse-out');
      },

      toggleLineTrigger: function toggleLineTrigger(id) {
        if (!this.get('clickableLine')) {
          return;
        }
        var toggleLineFn = this.get('toggleLine');
        if (toggleLineFn) {
          toggleLineFn(id);
        }
      },

      redirectTo: function redirectTo(elem) {
        if (!elem.link) {
          return;
        }
        getOwner(this).lookup('controller:application').transitionToRoute('companies.report', elem.link);
      }
    }
  });
});