define("due-dashboard/templates/components/icons/-trustpilot", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 5,
            "column": 6
          }
        },
        "moduleName": "due-dashboard/templates/components/icons/-trustpilot.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        dom.setNamespace("http://www.w3.org/2000/svg");
        var el1 = dom.createElement("svg");
        dom.setAttribute(el1, "viewBox", "0 0 32 24");
        dom.setAttribute(el1, "fill", "none");
        dom.setAttribute(el1, "preserveAspectRatio", "none");
        dom.setAttribute(el1, "xmlns", "http://www.w3.org/2000/svg");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("rect");
        dom.setAttribute(el2, "width", "32");
        dom.setAttribute(el2, "height", "24");
        dom.setAttribute(el2, "rx", "4");
        dom.setAttribute(el2, "fill", "#00B67A");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("path");
        dom.setAttribute(el2, "d", "M23 10.4318H17.6539L16.0026 5.34375L14.3461 10.4318L9 10.4267L13.3295 13.5745L11.6731 18.6574L16.0026 15.5148L20.3269 18.6574L18.6756 13.5745L23 10.4318Z");
        dom.setAttribute(el2, "fill", "white");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("path");
        dom.setAttribute(el2, "d", "M19.047 14.725L18.6755 13.5742L16.0024 15.5145L19.047 14.725Z");
        dom.setAttribute(el2, "fill", "#00B67A");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes() {
        return [];
      },
      statements: [],
      locals: [],
      templates: []
    };
  })());
});