define("due-dashboard/helpers/descending-order", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].Helper.helper(function (a, b) {
    if (a.value == null) {
      return 1;
    }
    if (b.value == null) {
      return -1;
    }
    if (a.value === b.value) {
      return 0;
    }
    return a.value < b.value ? 1 : -1;
  });
});