define('due-dashboard/components/due-table/elements/-breakdown', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  exports['default'] = Component.extend({
    tagName: 'div',
    classNames: ['report-breakdown'],

    total: 0,

    computedBreakdown: computed('value', function () {
      this.set('total', this.get('value.total'));
      this.set('title', this.get('value.title'));

      return this.get('value.distribution');
    }),

    didRender: function didRender() {
      this.set('width', this.element.getBoundingClientRect().width);
    }
  });
});
/**
 * @author Antoine Yvroud <antoine@diduenjoy.com>
 * @summary {{reports/array/-breakdown}} breakdown column used by {{reports/-array}}
 * @figma https://www.figma.com/file/rPjKBcCC30UG8l2ioELBYD/LV-Reports?node-id=2407%3A97157&t=1u2arcO3evaGPizt-0
 */