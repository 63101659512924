define('due-dashboard/components/due-btn', ['exports', 'ember-data', 'ember'], function (exports, _emberData, _ember) {
  /**
   * @author Youba SALMI <youba@diduenjoy.com>
   * @summary {{due-btn}} Simple button component
   *
   * @module
   * @argument {string} [icon] - button's icon required if no textKey is specified
   * @argument {string} [textKey] - key in the dictionary corresponding the button's text, required if icon is icon is not specified
   * @argument {string} size - size of the button
   * @argument {string} intent - intent of the button
   * @argument {string} [type] - button type, usefull in case of form submit
   * @argument {string} [customClass] - classes to customise the button
   * @argument {promise} [promise] - promise to watch for changing
   * @argument {boolean} [disabled] - boolean to disable the button
   * @argument {boolean} [routeTransition] - specify if it is a route transition button
   * @example <caption>Full usage example of {{due-btn}} component</caption>
   * Usage :
   * ```hbs
   * {{due-btn
   *    icon='tio-add'
   *    size='medium'
   *    intent='primary'
   *    textKey=''
   *    promise=promise
   *    customClass='test-custom'
   *    action=(action 'addNewButton')
   *    disabled=addButtonState
   * }}
   * ```
   */

  var computed = _ember['default'].computed;
  var Component = _ember['default'].Component;
  var run = _ember['default'].run;
  var RSVP = _ember['default'].RSVP;
  var $ = _ember['default'].$;
  exports['default'] = Component.extend({
    tagName: 'button',
    classNames: ['due-btn'],
    classNameBindings: ['customClass', 'buttonType', 'loading', 'disabled', 'size', 'intent'],
    attributeBindings: ['type'],
    scroll_to_error: true,
    loading: false,

    init: function init() {
      var text = this.get('text'),
          textKey = this.get('textKey'),
          icon = this.get('icon'),
          size = this.get('size'),
          intent = this.get('intent'),
          valid_intents = ['primary', 'secondary', 'tertiary', 'validation', 'danger'],
          valid_sizes = ['x-small', 'small', 'medium', 'large'];

      if (!size || !intent) {
        throw new Error('due-btn: both arguments size and intent are mandatory');
      } else if (!text && !textKey && !icon) {
        throw new Error('due-btn: at least icon or textKey or text need to be specified');
      } else if (intent && !valid_intents.includes(intent) || size && !valid_sizes.includes(size)) {
        throw new Error('due-btn: size and intent should have valid values, intent ' + intent + ' and size ' + size);
      }
      this._super.apply(this, arguments);
    },

    actionPromise: computed('promise', function () {
      var _this = this;

      var promise = [this.get('promise')],
          delay = this.get('routeTransition') ? 0 : 500;
      if (this.get('promise')) {
        return _emberData['default'].PromiseArray.create({
          promise: new RSVP.Promise(function (resolve, reject) {
            _this.updateButtonState(true);
            RSVP.allSettled(promise).then(function () {
              setTimeout(function () {
                resolve();
                _this.updateButtonState(false);
              }, delay);
            }, function () {
              _this.updateButtonState(false);
              _this.scrollToFirstError();
              reject();
            });
          })
        });
      }
    }),

    buttonType: computed('icon', 'textKey', function () {
      var hasIcon = typeof this.get('icon') !== 'undefined',
          hasText = typeof this.get('textKey') !== 'undefined';
      if (hasIcon && !hasText) return 'only-icon';else if (!hasIcon && hasText) return 'only-text';else // hasIcon && hasText
        return 'classic';
    }),

    displayText: computed('text', 'textKey', 'i18n.locale', function () {
      var text = this.get('text'),
          key = this.get('textKey');

      if (text) {
        return text;
      } else if (key) {
        var i18n = this.get('i18n');

        return i18n.t(key);
      } else {
        return '';
      }
    }),

    click: function click(event) {
      if (this.get('disabledEnter') && event.pointerId === -1) return false;
      if (this.get('action') && !this.get('loading') && !this.get('disabled')) {
        this.get('action')(event);
      }
      return true;
    },

    updateButtonState: function updateButtonState(state) {
      if (!this.isDestroyed) {
        this.set('loading', state);
      }
    },

    scrollToFirstError: function scrollToFirstError() {
      run.later(this, function () {
        if (!this.isDestroyed && this.get('scroll_to_error')) {
          $('html, body').animate({
            scrollTop: $('.error').first().offset().top - 100
          }, 500);
        }
      }, 200);
    }
  });
});