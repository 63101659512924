define('due-dashboard/models/data-query', ['exports', 'ember-data'], function (exports, _emberData) {
  var Model = _emberData['default'].Model;
  var attr = _emberData['default'].attr;
  exports['default'] = Model.extend({
    date_range: attr(),
    content_data: attr(),
    filters: attr(),
    properties: attr(),
    group: attr('string'),
    group_by: attr('string'),
    compare_to_period: attr('string'),
    with_link: attr('boolean'),
    object_ids: attr(),
    object_id: attr('string'),
    sort: attr(),
    lvls: attr(),
    kind: attr('string'),
    subkind: attr('string'),
    page_number: attr('number'),
    page_size: attr('number'),
    page_count: attr('number'),
    sortable: attr('boolean'),
    front_format: attr('boolean'),
    tz: attr('string'),
    segment_ids: attr(),
    values_hierarchy: attr(),
    total_count: attr('number'),

    setParameters: function setParameters(parameters) {
      var _this = this;

      var fields = ['filters', 'date_range', 'properties', 'front_format', 'group', 'group_by', 'compare_to_period', 'with_link', 'object_ids', 'object_id', 'sort', 'lvls', 'kind', 'subkind', 'page_number', 'page_size', 'pagination', 'translations', 'query_segments', 'tz', 'segment_ids', 'values_hierarchy', 'sortable'];
      fields.forEach(function (field) {
        _this.set(field, parameters.get(field));
      });
    }
  });
});