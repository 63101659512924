define('due-dashboard/objects/reports/data-converter/question-table-converter', ['exports', 'due-dashboard/objects/reports/data-converter/table-converter'], function (exports, _dueDashboardObjectsReportsDataConverterTableConverter) {
  var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

  exports['default'] = _dueDashboardObjectsReportsDataConverterTableConverter['default'].extend({

    questionsIcons: {
      'rating': 'star_outlined',
      'long_text_input': 'comment_text_outlined',
      'short_text_input': 'comment_text_outlined',
      'single_select': 'checkmark_circle_outlined',
      'multiple_select': 'checkmark_circle_outlined'
    },

    getHeaders: function getHeaders(tableParams, currentLanguage) {
      var headerTxt = tableParams.translations[currentLanguage].headers;
      var headerTooltip = tableParams.translations[currentLanguage].header_tooltip;
      headerTxt = headerTxt.map(function (txt) {
        return txt.length > 70 ? txt.slice(0, 70) + '...' : txt;
      });

      var headers = [];
      var mainColumnNb = tableParams.properties.filter(function (prop) {
        return prop.main_column;
      }).length;

      if (mainColumnNb < 1 || mainColumnNb == undefined) {
        return [];
      }
      headerTxt.forEach(function (txt, index) {
        var columnType = tableParams.properties[index].type;
        var header = {
          type: columnType,
          label: txt,
          main_column: index < mainColumnNb,
          sortable: index >= mainColumnNb && tableParams.sortable && columnType != 'breakdown'
        };
        if (headerTooltip != null && headerTooltip[txt]) {
          header.tooltip = headerTooltip[txt];
        }
        headers.push(header);
      });
      return headers;
    },

    getRows: function getRows(data, tableParams, currentLanguage, i18n) {
      var rows = [];
      var content = this._getQuestionsContent(data, tableParams, currentLanguage, i18n);
      content.forEach(function (line, index) {
        return rows.push({ data: line });
      });
      return rows;
    },

    _formatBreakdown: function _formatBreakdown(elem, properties, tableParams, currentLanguage) {
      var value = _extends({}, elem.value);

      if (value.breakdown) {
        value.distribution = value.breakdown;
      }

      var distribution = value.distribution;
      var computedBreakdown = {};
      var total = 0;

      if (!distribution) {
        value.distribution = [];
        return _extends({}, elem, { value: value });
      }

      if (distribution['_total_multi'] !== undefined) {
        total = distribution['_total_multi'];
        delete distribution['_total_multi'];
      } else {
        Object.values(distribution).forEach(function (sectionTotal) {
          total += sectionTotal;
        });
      }
      value.total = total;

      if (total == 0) {
        value.distribution = [];
        return _extends({}, elem, { value: value });
      }

      computedBreakdown = Object.keys(distribution).map(function (sectionKey, idx) {
        var sectionTotal = distribution[sectionKey];
        var sectionPercentage = sectionTotal / total * 100;
        var color = '';
        var title = '';

        if (properties.kpi.includes('sentiment')) {
          title = tableParams.translations[currentLanguage].sentiments[sectionKey].title;
        } else {
          title = sectionKey;
        }

        var color_e = null;
        if (properties.kpi && properties.kpi.includes('sentiment')) {
          color_e = properties.distribution[sectionKey];
        } else if (properties.segment_id && tableParams['distribution_' + properties.segment_id]) {
          color_e = tableParams['distribution_' + properties.segment_id][idx % tableParams['distribution_' + properties.segment_id].length];
        } else {
          color_e = properties.distribution[idx % properties.distribution.length];
        }
        color = color_e ? color_e.color : '#e6e6e6'; // default color for missing distribution entries

        return {
          color: color,
          percentage: Math.round(sectionPercentage),
          style: 'width: ' + sectionPercentage + '%; background-color: ' + color + ';',
          title: title,
          total: sectionTotal || 0
        };
      });
      value.distribution = computedBreakdown;
      return _extends({}, elem, { value: value, type: 'breakdown' });
    },

    _getQuestionsContent: function _getQuestionsContent(content, tableParams, currentLanguage, i18n) {
      var _this = this;

      var serializedQuestions = [];
      content.forEach(function (line, lineIdx) {
        var ratingColumn = line.filter(function (q) {
          return q.question_rating;
        })[0],
            iconColumn = { type: 'icon', value: ratingColumn ? _this.questionsIcons[ratingColumn.value.sub_label] : undefined },
            questionNameColumn = { type: 'main-label', value: tableParams.translations[currentLanguage].questions[lineIdx] };

        serializedQuestions.push([]);
        serializedQuestions[lineIdx].push(_extends({}, iconColumn, { main_column: true }));
        serializedQuestions[lineIdx].push(_extends({}, questionNameColumn, { main_column: true }));

        line.forEach(function (elem, idx) {
          if (elem.question_rating) {
            if (!elem.isAlreadyFormatted) {
              var sub_label = elem.value.sub_label ? elem.value.sub_label : 'not_found';
              elem.value.sub_label = i18n.t('components.reports.array.questions.types.' + sub_label);
              elem.isAlreadyFormatted = true;
            }
            serializedQuestions[lineIdx].push(_extends({}, elem, { main_column: true }));
          } else if (elem.type == 'breakdown' || elem.value.breakdown) {
            var breakdown = _this._formatBreakdown(elem, tableParams.properties[idx + 2], tableParams, currentLanguage);
            breakdown.value.title = tableParams.translations[currentLanguage].headers[idx + 2];
            serializedQuestions[lineIdx].push(breakdown);
          } else if (elem.type == 'number-trend' || elem.type.startsWith('variation-')) {
            serializedQuestions[lineIdx].push(_this._formatNumberTrend(elem, tableParams.properties[idx + 2], tableParams, i18n));
          } else if (['positive-impact', 'negative-impact'].includes(elem.type)) {
            serializedTags[lineIdx].push(_extends({}, elem, { positiveImpactHideValue: tableParams.positiveImpactHideValue, negativeImpactHideValue: tableParams.negativeImpactHideValue }));
          } else {
            serializedQuestions[lineIdx].push(_extends({}, elem, { kpi: tableParams.properties[idx + 2].kpi, attrs: tableParams.properties[idx + 2].attrs }));
          }
        });
      });
      return serializedQuestions;
    },

    formatData: function formatData(params) {
      var tableParams = params.tableParams;
      var data = params.data;
      var currentLanguage = params.currentLanguage;
      var i18n = params.i18n;

      return {
        headers: this.getHeaders(tableParams, currentLanguage || 'EN'),
        rows: this.getRows(data, tableParams, currentLanguage || 'EN', i18n)
      };
    }
  });
});