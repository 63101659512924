define('due-dashboard/helpers/default-survey-v2-translations', ['exports', 'ember'], function (exports, _ember) {
  exports.defaultSurveyV2Translations = defaultSurveyV2Translations;

  function defaultSurveyV2Translations(companyName, surveyType) {
    var translations = defaultTranslations[surveyType];
    if (!translations) return;

    Object.keys(translations).forEach(function (tag) {
      if (tag !== '_languages') {
        Object.keys(translations[tag].elements).forEach(function (stepIdx) {
          Object.keys(translations[tag].elements[stepIdx]).forEach(function (elemIdx) {
            if (translations[tag].elements[stepIdx][elemIdx].label && translations[tag].elements[stepIdx][elemIdx].label.includes("{company_name}")) {
              translations[tag].elements[stepIdx][elemIdx].label = translations[tag].elements[stepIdx][elemIdx].label.replace("{company_name}", companyName); // replace company name default var
            }
          });
        });
      }
    });

    return translations;
  }

  exports['default'] = _ember['default'].Helper.helper(defaultSurveyV2Translations);

  var defaultTranslations = {
    nps: {
      "en": {
        "elements": {
          "0": {
            "0": {
              "text": "It has been a pleasure to welcome you!"
            },
            "1": {
              "label": "Would you recommend {company_name} to your friends and family?",
              "na_label": "N/A",
              "legend": {
                "low_score": "0 - Absolutely not",
                "high_score": "10 - Yes, absolutely"
              },
              "rating": {
                "0": "0",
                "1": "1",
                "2": "2",
                "3": "3",
                "4": "4",
                "5": "5",
                "6": "6",
                "7": "7",
                "8": "8",
                "9": "9",
                "10": "10"
              }
            },
            "2": {
              "label": "Tell us more about your experience",
              "placeholder": "Write here"
            },
            "3": {
              "previous_button_text": "Back",
              "next_button_text": "Submit"
            }
          },
          "2": {
            "0": {
              "text": "Thank you for your feedback which is much appreciated!\n\nWe hope to see you again soon!"
            },
            "1": {
              "text": "Discover our latest innovations!"
            }
          }
        }
      },
      "fr": {
        "elements": {
          "0": {
            "0": {
              "text": "Ce fut un plaisir de vous accueillir !"
            },
            "1": {
              "label": "Recommenderiez-vous {company_name} à vos amis et à votre famille ?",
              "na_label": "N/A",
              "legend": {
                "low_score": "0 - Non, pas du tout",
                "high_score": "10 - Oui, tout à fait"
              },
              "rating": {
                "0": "0",
                "1": "1",
                "2": "2",
                "3": "3",
                "4": "4",
                "5": "5",
                "6": "6",
                "7": "7",
                "8": "8",
                "9": "9",
                "10": "10"
              }
            },
            "2": {
              "label": "Parlez nous de votre votre expérience",
              "placeholder": "Écrivez ici"
            },
            "3": {
              "previous_button_text": "Retour",
              "next_button_text": "Soumettre"
            }
          },
          "2": {
            "0": {
              "text": "Merci pour votre retour qui nous est très précieux !\n\nNous espérons vous revoir bientôt !"
            },
            "1": {
              "text": "Découvrez nos dernières innovations !"
            }
          }
        }
      },
      "es": {
        "elements": {
          "0": {
            "0": {
              "text": "¡Ha sido un placer darle la bienvenida!"
            },
            "1": {
              "label": "¿Recomendaría {company_name} a sus amigos y familiares?",
              "na_label": "N/A",
              "legend": {
                "low_score": "0 - En absoluto",
                "high_score": "10 - Sí, claro"
              },
              "rating": {
                "0": "0",
                "1": "1",
                "2": "2",
                "3": "3",
                "4": "4",
                "5": "5",
                "6": "6",
                "7": "7",
                "8": "8",
                "9": "9",
                "10": "10"
              }
            },
            "2": {
              "label": "Cuéntenos más sobre su experiencia",
              "placeholder": "Escriba aquí"
            },
            "3": {
              "previous_button_text": "Atrás",
              "next_button_text": "Entregar"
            }
          },
          "2": {
            "0": {
              "text": "¡Gracias por sus comentarios que son muy apreciados!\n\n¡Esperamos verle pronto!"
            },
            "1": {
              "text": "¡Descubra nuestras últimas innovaciones!"
            }
          }
        }
      },
      "nl": {
        "elements": {
          "0": {
            "0": {
              "text": "Het was een genoegen u te mogen verwelkomen!"
            },
            "1": {
              "label": "Zou u {company_name} aan u aanbevelen aan uw vrienden en familie?",
              "na_label": "N/A",
              "legend": {
                "low_score": "0 - Absoluut niet",
                "high_score": "10 - Ja, absoluut"
              },
              "rating": {
                "0": "0",
                "1": "1",
                "2": "2",
                "3": "3",
                "4": "4",
                "5": "5",
                "6": "6",
                "7": "7",
                "8": "8",
                "9": "9",
                "10": "10"
              }
            },
            "2": {
              "label": "Vertel ons meer over uw ervaring",
              "placeholder": "Schrijf hier"
            },
            "3": {
              "previous_button_text": "Terug",
              "next_button_text": "Indienen"
            }
          },
          "2": {
            "0": {
              "text": "Hartelijk dank voor uw waardevolle feedback!\n\nWe hopen u snel weer te zien!"
            },
            "1": {
              "text": "Ontdek onze nieuwste innovaties!"
            }
          }
        }
      },
      "zh": {
        "elements": {
          "0": {
            "0": {
              "text": "很高兴迎接您！"
            },
            "1": {
              "label": "您会向朋友和家人推荐{company_name}吗？",
              "na_label": "N/A",
              "legend": {
                "low_score": "0 - 完全不会",
                "high_score": "10 - 一定会"
              },
              "rating": {
                "0": "0",
                "1": "1",
                "2": "2",
                "3": "3",
                "4": "4",
                "5": "5",
                "6": "6",
                "7": "7",
                "8": "8",
                "9": "9",
                "10": "10"
              }
            },
            "2": {
              "label": "请告诉我们更多关于您的体验",
              "placeholder": "在此填写"
            },
            "3": {
              "previous_button_text": "返回",
              "next_button_text": "提交"
            }
          },
          "2": {
            "0": {
              "text": "感谢您宝贵的反馈！\n\n希望很快再见到您！"
            },
            "1": {
              "text": "探索我们的最新创新！"
            }
          }
        }
      },
      "zht": {
        "elements": {
          "0": {
            "0": {
              "text": "很高興迎接您！"
            },
            "1": {
              "label": "您會向朋友和家人推薦{company_name}嗎？",
              "na_label": "N/A",
              "legend": {
                "low_score": "0 - 完全不會",
                "high_score": "10 - 一定會"
              },
              "rating": {
                "0": "0",
                "1": "1",
                "2": "2",
                "3": "3",
                "4": "4",
                "5": "5",
                "6": "6",
                "7": "7",
                "8": "8",
                "9": "9",
                "10": "10"
              }
            },
            "2": {
              "label": "請告訴我們更多關於您的體驗",
              "placeholder": "在此填寫"
            },
            "3": {
              "previous_button_text": "回傳",
              "next_button_text": "提交"
            }
          },
          "2": {
            "0": {
              "text": "感謝您寶貴的反饋！\n\n希望很快再見到您！"
            },
            "1": {
              "text": "探索我們的最新創新！"
            }
          }
        }
      },
      "ko": {
        "elements": {
          "0": {
            "0": {
              "text": "환영해 드릴 수 있어 기뻤습니다!"
            },
            "1": {
              "label": "{company_name}를 친구와 가족에게 추천하시겠습니까?",
              "na_label": "N/A",
              "legend": {
                "low_score": "0 - 전혀 아니다",
                "high_score": "10 - 매우 그렇다"
              },
              "rating": {
                "0": "0",
                "1": "1",
                "2": "2",
                "3": "3",
                "4": "4",
                "5": "5",
                "6": "6",
                "7": "7",
                "8": "8",
                "9": "9",
                "10": "10"
              }
            },
            "2": {
              "label": "귀하의 경험에 대해 더 자세히 말씀해 주세요",
              "placeholder": "여기에 작성하세요"
            },
            "3": {
              "previous_button_text": "뒤로",
              "next_button_text": "제출하다"
            }
          },
          "2": {
            "0": {
              "text": "귀중한 피드백 감사합니다!\n\n곧 다시 뵙기를 희망합니다!"
            },
            "1": {
              "text": "최신 혁신을 확인해보세요!"
            }
          }
        }
      },
      "ja": {
        "elements": {
          "0": {
            "0": {
              "text": "お迎えできて光栄でした！"
            },
            "1": {
              "label": "{company_name}を友人や家族に推薦しますか？",
              "na_label": "N/A",
              "legend": {
                "low_score": "0 - 全く推薦しない",
                "high_score": "10 - 強く推薦する"
              },
              "rating": {
                "0": "0",
                "1": "1",
                "2": "2",
                "3": "3",
                "4": "4",
                "5": "5",
                "6": "6",
                "7": "7",
                "8": "8",
                "9": "9",
                "10": "10"
              }
            },
            "2": {
              "label": "ご体験についてさらにお聞かせください",
              "placeholder": "こちらにご記入ください"
            },
            "3": {
              "previous_button_text": "戻る",
              "next_button_text": "提出する"
            }
          },
          "2": {
            "0": {
              "text": "貴重なフィードバックをありがとうございます！\n\nまたのお越しをお待ちしております！"
            },
            "1": {
              "text": "最新のイノベーションをご覧ください！"
            }
          }
        }
      },
      "th": {
        "elements": {
          "0": {
            "0": {
              "text": "ยินดีที่ได้ต้อนรับคุณ!"
            },
            "1": {
              "label": "คุณจะแนะนำ {company_name} ให้เพื่อนและครอบครัวของคุณหรือไม่?",
              "na_label": "N/A",
              "legend": {
                "low_score": "0 - ไม่แนะนำอย่างยิ่ง",
                "high_score": "10 - แนะนำอย่างยิ่ง"
              },
              "rating": {
                "0": "0",
                "1": "1",
                "2": "2",
                "3": "3",
                "4": "4",
                "5": "5",
                "6": "6",
                "7": "7",
                "8": "8",
                "9": "9",
                "10": "10"
              }
            },
            "2": {
              "label": "บอกเราเพิ่มเติมเกี่ยวกับประสบการณ์ของคุณ",
              "placeholder": "เขียนที่นี่"
            },
            "3": {
              "previous_button_text": "ย้อนกลับ",
              "next_button_text": "ส่ง"
            }
          },
          "2": {
            "0": {
              "text": "ขอบคุณสำหรับข้อเสนอแนะที่มีค่าของคุณ!\n\nหวังว่าจะได้พบคุณอีกเร็วๆ นี้!"
            },
            "1": {
              "text": "ค้นพบนวัตกรรมล่าสุดของเรา!"
            }
          }
        }
      },
      "de": {
        "elements": {
          "0": {
            "0": {
              "text": "Es war uns eine Freude, Sie willkommen zu heißen!"
            },
            "1": {
              "label": "Würden Sie {company_name} Ihren Freunden und Ihrer Familie empfehlen?",
              "na_label": "N/A",
              "legend": {
                "low_score": "0 - Auf keinen Fall",
                "high_score": "10 - Ja, auf jeden Fall"
              },
              "rating": {
                "0": "0",
                "1": "1",
                "2": "2",
                "3": "3",
                "4": "4",
                "5": "5",
                "6": "6",
                "7": "7",
                "8": "8",
                "9": "9",
                "10": "10"
              }
            },
            "2": {
              "label": "Erzählen Sie uns mehr über Ihre Erfahrung",
              "placeholder": "Hier schreiben"
            },
            "3": {
              "previous_button_text": "Zurück",
              "next_button_text": "Einreichen"
            }
          },
          "2": {
            "0": {
              "text": "Vielen Dank für Ihr wertvolles Feedback!\n\nWir hoffen, Sie bald wiederzusehen!"
            },
            "1": {
              "text": "Entdecken Sie unsere neuesten Innovationen!"
            }
          }
        }
      },
      "vi": {
        "elements": {
          "0": {
            "0": {
              "text": "Thật vui được chào đón bạn!"
            },
            "1": {
              "label": "Bạn có sẽ giới thiệu {company_name} cho bạn bè và gia đình không?",
              "na_label": "N/A",
              "legend": {
                "low_score": "0 - Hoàn toàn không",
                "high_score": "10 - Có, chắc chắn"
              },
              "rating": {
                "0": "0",
                "1": "1",
                "2": "2",
                "3": "3",
                "4": "4",
                "5": "5",
                "6": "6",
                "7": "7",
                "8": "8",
                "9": "9",
                "10": "10"
              }
            },
            "2": {
              "label": "Hãy chia sẻ thêm về trải nghiệm của bạn",
              "placeholder": "Viết tại đây"
            },
            "3": {
              "previous_button_text": "Quay lại",
              "next_button_text": "Nộp"
            }
          },
          "2": {
            "0": {
              "text": "MCảm ơn bạn rất nhiều vì phản hồi quý báu!\n\nChúng tôi hy vọng sớm gặp lại bạn!"
            },
            "1": {
              "text": "Khám phá những đổi mới mới nhất của chúng tôi!"
            }
          }
        }
      },
      "id": {
        "elements": {
          "0": {
            "0": {
              "text": "Senang dapat menyambut Anda!"
            },
            "1": {
              "label": "Apakah Anda akan merekomendasikan {company_name} kepada teman dan keluarga Anda?",
              "na_label": "N/A",
              "legend": {
                "low_score": "0 - Sama sekali tidak",
                "high_score": "10 - Ya, tentu saja"
              },
              "rating": {
                "0": "0",
                "1": "1",
                "2": "2",
                "3": "3",
                "4": "4",
                "5": "5",
                "6": "6",
                "7": "7",
                "8": "8",
                "9": "9",
                "10": "10"
              }
            },
            "2": {
              "label": "Ceritakan lebih lanjut tentang pengalaman Anda",
              "placeholder": "Tulis di sini"
            },
            "3": {
              "previous_button_text": "Kembali",
              "next_button_text": "Kirim"
            }
          },
          "2": {
            "0": {
              "text": "Terima kasih atas umpan balik Anda yang sangat berharga!\n\nKami harap dapat bertemu Anda lagi segera!"
            },
            "1": {
              "text": "Temukan inovasi terbaru kami!"
            }
          }
        }
      },
      "mn": {
        "elements": {
          "0": {
            "0": {
              "text": "Таныг угтан авч байгаадаа баяртай байна!"
            },
            "1": {
              "label": "Та {company_name}-г найз нөхөд, гэр бүлдээ санал болгох уу?",
              "na_label": "N/A",
              "legend": {
                "low_score": "0 - Огт үгүй",
                "high_score": "10 - Тийм, заавал"
              },
              "rating": {
                "0": "0",
                "1": "1",
                "2": "2",
                "3": "3",
                "4": "4",
                "5": "5",
                "6": "6",
                "7": "7",
                "8": "8",
                "9": "9",
                "10": "10"
              }
            },
            "2": {
              "label": "Өөрийн туршлагаас дэлгэрэнгүй хуваалцана уу",
              "placeholder": "Энд бичнэ үү"
            },
            "3": {
              "previous_button_text": "Буцах",
              "next_button_text": "Илгээх"
            }
          },
          "2": {
            "0": {
              "text": "Таны үнэтэй санал хүсэлтэд маш их баярлалаа!\n\nТантай удахгүй дахин уулзахыг найдаж байна!"
            },
            "1": {
              "text": "Манай шинэ инновациудыг нээгээрэй!"
            }
          }
        }
      },
      "it": {
        "elements": {
          "0": {
            "0": {
              "text": "È stato un piacere darle il benvenuto!"
            },
            "1": {
              "label": "Consiglierebbe {company_name} ai suoi amici e familiari?",
              "na_label": "N/A",
              "legend": {
                "low_score": "0 - Assolutamente no",
                "high_score": "10 - Sì, assolutamente"
              },
              "rating": {
                "0": "0",
                "1": "1",
                "2": "2",
                "3": "3",
                "4": "4",
                "5": "5",
                "6": "6",
                "7": "7",
                "8": "8",
                "9": "9",
                "10": "10"
              }
            },
            "2": {
              "label": "Ci racconti di più sulla sua esperienza",
              "placeholder": "Scriva qui"
            },
            "3": {
              "previous_button_text": "Indietro",
              "next_button_text": "Invia"
            }
          },
          "2": {
            "0": {
              "text": "Grazie per il suo prezioso feedback!\n\nSperiamo di rivederla presto!"
            },
            "1": {
              "text": "Scopra le nostre ultime innovazioni!"
            }
          }
        }
      },
      "pt": {
        "elements": {
          "0": {
            "0": {
              "text": "Foi um prazer recebê-lo!"
            },
            "1": {
              "label": "Recomendaria a {company_name} aos seus amigos e familiares?",
              "na_label": "N/A",
              "legend": {
                "low_score": "0 - Absolutamente não",
                "high_score": "10 - Sim, absolutamente"
              },
              "rating": {
                "0": "0",
                "1": "1",
                "2": "2",
                "3": "3",
                "4": "4",
                "5": "5",
                "6": "6",
                "7": "7",
                "8": "8",
                "9": "9",
                "10": "10"
              }
            },
            "2": {
              "label": "Conte-nos mais sobre a sua experiência",
              "placeholder": "Escreva aqui"
            },
            "3": {
              "previous_button_text": "Voltar",
              "next_button_text": "Submeter"
            }
          },
          "2": {
            "0": {
              "text": "Obrigado pelo seu feedback que é muito apreciado!\n\nEsperamos vê-lo novamente em breve!"
            },
            "1": {
              "text": "Descubra as nossas últimas inovações!"
            }
          }
        }
      },
      "pt_br": {
        "elements": {
          "0": {
            "0": {
              "text": "Foi um prazer receber você!"
            },
            "1": {
              "label": "Você recomendaria a {company_name} para seus amigos e familiares?",
              "na_label": "N/A",
              "legend": {
                "low_score": "0 - Definitivamente não",
                "high_score": "10 - Sim, com certeza"
              },
              "rating": {
                "0": "0",
                "1": "1",
                "2": "2",
                "3": "3",
                "4": "4",
                "5": "5",
                "6": "6",
                "7": "7",
                "8": "8",
                "9": "9",
                "10": "10"
              }
            },
            "2": {
              "label": "Conte-nos mais sobre sua experiência",
              "placeholder": "Escreva aqui"
            },
            "3": {
              "previous_button_text": "Voltar",
              "next_button_text": "Enviar"
            }
          },
          "2": {
            "0": {
              "text": "Obrigado pelo seu feedback que é muito importante para nós!\n\nEsperamos ver você novamente em breve!"
            },
            "1": {
              "text": "Descubra as nossas últimas inovações!"
            }
          }
        }
      },
      "es_mx": {
        "elements": {
          "0": {
            "0": {
              "text": "¡Ha sido un placer darle la bienvenida!"
            },
            "1": {
              "label": "¿Recomendaría {company_name} a sus amigos y familiares?",
              "na_label": "N/A",
              "legend": {
                "low_score": "0 - Para nada",
                "high_score": "10 - Sí, definitivamente"
              },
              "rating": {
                "0": "0",
                "1": "1",
                "2": "2",
                "3": "3",
                "4": "4",
                "5": "5",
                "6": "6",
                "7": "7",
                "8": "8",
                "9": "9",
                "10": "10"
              }
            },
            "2": {
              "label": "Cuéntenos más sobre su experiencia",
              "placeholder": "Escriba aquí"
            },
            "3": {
              "previous_button_text": "Atrás",
              "next_button_text": "Siguiente"
            }
          },
          "2": {
            "0": {
              "text": "¡Gracias por sus comentarios que son muy apreciados!\n\n¡Esperamos verle pronto!"
            },
            "1": {
              "text": "¡Descubra nuestras últimas innovaciones!"
            }
          }
        }
      },
      "fr_ca": {
        "elements": {
          "0": {
            "0": {
              "text": "Ce fut un plaisir de vous accueillir !"
            },
            "1": {
              "label": "Recommenderiez-vous {company_name} à vos amis et à votre famille ?",
              "na_label": "N/A",
              "legend": {
                "low_score": "0 - Non, pas du tout",
                "high_score": "10 - Oui, tout à fait"
              },
              "rating": {
                "0": "0",
                "1": "1",
                "2": "2",
                "3": "3",
                "4": "4",
                "5": "5",
                "6": "6",
                "7": "7",
                "8": "8",
                "9": "9",
                "10": "10"
              }
            },
            "2": {
              "label": "Parlez nous de votre votre expérience",
              "placeholder": "Écrivez ici"
            },
            "3": {
              "previous_button_text": "Retour",
              "next_button_text": "Soumettre"
            }
          },
          "2": {
            "0": {
              "text": "Merci pour votre retour qui nous est très précieux !\n\nNous espérons vous revoir bientôt !"
            },
            "1": {
              "text": "Découvrez nos dernières innovations !"
            }
          }
        }
      },
      "ru": {
        "elements": {
          "0": {
            "0": {
              "text": "Было приятно приветствовать вас!"
            },
            "1": {
              "label": "Порекомендовали бы вы {company_name} своим друзьям и семье?",
              "na_label": "N/A",
              "legend": {
                "low_score": "0 - Определенно нет",
                "high_score": "10 - Определенно да"
              },
              "rating": {
                "0": "0",
                "1": "1",
                "2": "2",
                "3": "3",
                "4": "4",
                "5": "5",
                "6": "6",
                "7": "7",
                "8": "8",
                "9": "9",
                "10": "10"
              }
            },
            "2": {
              "label": "Расскажите подробнее о вашем опыте",
              "placeholder": "Напишите здесь"
            },
            "3": {
              "previous_button_text": "Назад",
              "next_button_text": "Представлять на рассмотрение"
            }
          },
          "2": {
            "0": {
              "text": "Спасибо за ваш отзыв, который мы высоко ценим!\n\nНадеемся увидеть вас снова!"
            },
            "1": {
              "text": "Откройте для себя наши последние инновации!"
            }
          }
        }
      },
      "pl": {
        "elements": {
          "0": {
            "0": {
              "text": "Było nam miło Państwa gościć!"
            },
            "1": {
              "label": "Czy poleciliby Państwo {company_name} swoim znajomym i rodzinie?",
              "na_label": "N/A",
              "legend": {
                "low_score": "0 - Zdecydowanie nie",
                "high_score": "10 - Zdecydowanie tak"
              },
              "rating": {
                "0": "0",
                "1": "1",
                "2": "2",
                "3": "3",
                "4": "4",
                "5": "5",
                "6": "6",
                "7": "7",
                "8": "8",
                "9": "9",
                "10": "10"
              }
            },
            "2": {
              "label": "Prosimy o więcej szczegółów dotyczących Państwa doświadczenia",
              "placeholder": "Proszę pisać tutaj"
            },
            "3": {
              "previous_button_text": "Wstecz",
              "next_button_text": "Składać"
            }
          },
          "2": {
            "0": {
              "text": "Dziękujemy za Państwa cenną opinię!\n\nMamy nadzieję zobaczyć Państwa ponownie!"
            },
            "1": {
              "text": "Odkryj nasze najnowsze innowacje!"
            }
          }
        }
      },
      "cs": {
        "elements": {
          "0": {
            "0": {
              "text": "Bylo nám potěšením vás přivítat!"
            },
            "1": {
              "label": "Doporučili byste {company_name} svým přátelům a rodině?",
              "na_label": "N/A",
              "legend": {
                "low_score": "0 - Rozhodně ne",
                "high_score": "10 - Rozhodně ano"
              },
              "rating": {
                "0": "0",
                "1": "1",
                "2": "2",
                "3": "3",
                "4": "4",
                "5": "5",
                "6": "6",
                "7": "7",
                "8": "8",
                "9": "9",
                "10": "10"
              }
            },
            "2": {
              "label": "Řekněte nám více o vaší zkušenosti",
              "placeholder": "Pište zde"
            },
            "3": {
              "previous_button_text": "Zpět",
              "next_button_text": "Předložit"
            }
          },
          "2": {
            "0": {
              "text": "Děkujeme za vaši zpětnou vazbu, které si velmi vážíme!\n\nDoufáme, že se brzy uvidíme!"
            },
            "1": {
              "text": "Objevte naše nejnovější inovace!"
            }
          }
        }
      },
      "el": {
        "elements": {
          "0": {
            "0": {
              "text": "Ήταν χαρά μας που σας υποδεχτήκαμε!"
            },
            "1": {
              "label": "Θα συστήνατε την {company_name} στους φίλους και την οικογένειά σας;",
              "na_label": "N/A",
              "legend": {
                "low_score": "0 - Σίγουρα όχι",
                "high_score": "10 - Ναι, σίγουρα"
              },
              "rating": {
                "0": "0",
                "1": "1",
                "2": "2",
                "3": "3",
                "4": "4",
                "5": "5",
                "6": "6",
                "7": "7",
                "8": "8",
                "9": "9",
                "10": "10"
              }
            },
            "2": {
              "label": "Πείτε μας περισσότερα για την εμπειρία σας",
              "placeholder": "Γράψτε εδώ"
            },
            "3": {
              "previous_button_text": "Πίσω",
              "next_button_text": "Υποτάσσομαι"
            }
          },
          "2": {
            "0": {
              "text": "Σας ευχαριστούμε για τα σχόλιά σας που εκτιμούμε ιδιαίτερα!\n\nΕλπίζουμε να σας δούμε ξανά σύντομα!"
            },
            "1": {
              "text": "Ανακαλύψτε τις τελευταίες καινοτομίες μας!"
            }
          }
        }
      },
      "fi": {
        "elements": {
          "0": {
            "0": {
              "text": "Oli ilo toivottaa teidät tervetulleeksi!"
            },
            "1": {
              "label": "Suosittelisitteko {companyu_name}ta ystävillenne ja perheellenne?",
              "na_label": "N/A",
              "legend": {
                "low_score": "0 - En missään tapauksessa",
                "high_score": "10 - Kyllä, ehdottomasti"
              },
              "rating": {
                "0": "0",
                "1": "1",
                "2": "2",
                "3": "3",
                "4": "4",
                "5": "5",
                "6": "6",
                "7": "7",
                "8": "8",
                "9": "9",
                "10": "10"
              }
            },
            "2": {
              "label": "Kertokaa lisää kokemuksestanne",
              "placeholder": "Kirjoittakaa tähän"
            },
            "3": {
              "previous_button_text": "Takaisin",
              "next_button_text": "Lähetä"
            }
          },
          "2": {
            "0": {
              "text": "Kiitos palautteestanne, jota arvostamme suuresti!\n\nToivomme näkevämme teidät pian uudelleen!"
            },
            "1": {
              "text": "Tutustu uusimpiin innovaatioihimme!"
            }
          }
        }
      },
      "sv": {
        "elements": {
          "0": {
            "0": {
              "text": "Det har varit ett nöje att välkomna dig!"
            },
            "1": {
              "label": "Skulle du rekommendera {company_name} till dina vänner och familj?",
              "na_label": "N/A",
              "legend": {
                "low_score": "0 - Absolut inte",
                "high_score": "10 - Ja, absolut"
              },
              "rating": {
                "0": "0",
                "1": "1",
                "2": "2",
                "3": "3",
                "4": "4",
                "5": "5",
                "6": "6",
                "7": "7",
                "8": "8",
                "9": "9",
                "10": "10"
              }
            },
            "2": {
              "label": "Berätta mer om din upplevelse",
              "placeholder": "Skriv här"
            },
            "3": {
              "previous_button_text": "Späť",
              "next_button_text": "Överlämna"
            }
          },
          "2": {
            "0": {
              "text": "Tack för din värdefulla feedback!\n\nVi hoppas få se dig snart igen!"
            },
            "1": {
              "text": "Upptäck våra senaste innovationer!"
            }
          }
        }
      },
      "no": {
        "elements": {
          "0": {
            "0": {
              "text": "Det har vært en glede å ønske deg velkommen!"
            },
            "1": {
              "label": "Ville du anbefalt {company_name} til venner og familie?",
              "na_label": "N/A",
              "legend": {
                "low_score": "0 - Absolutt ikke ",
                "high_score": "10 - Ja, absolutt"
              },
              "rating": {
                "0": "0",
                "1": "1",
                "2": "2",
                "3": "3",
                "4": "4",
                "5": "5",
                "6": "6",
                "7": "7",
                "8": "8",
                "9": "9",
                "10": "10"
              }
            },
            "2": {
              "label": "Fortell oss mer om din opplevelse",
              "placeholder": "Skriv her"
            },
            "3": {
              "previous_button_text": "Tilbake",
              "next_button_text": "Send inn"
            }
          },
          "2": {
            "0": {
              "text": "Takk for din tilbakemelding som er høyt verdsatt!\n\nVi håper å se deg igjen snart!"
            },
            "1": {
              "text": "Oppdag våre nyeste innovasjoner!"
            }
          }
        }
      },
      "da": {
        "elements": {
          "0": {
            "0": {
              "text": "Det har været en fornøjelse at byde dig velkommen!"
            },
            "1": {
              "label": "Ville du anbefale {company_name} til dine venner og familie?",
              "na_label": "N/A",
              "legend": {
                "low_score": "0 - Absolut ikke",
                "high_score": "10 - Ja, absolut"
              },
              "rating": {
                "0": "0",
                "1": "1",
                "2": "2",
                "3": "3",
                "4": "4",
                "5": "5",
                "6": "6",
                "7": "7",
                "8": "8",
                "9": "9",
                "10": "10"
              }
            },
            "2": {
              "label": "Fortæl os mere om din oplevelse",
              "placeholder": "Skriv her"
            },
            "3": {
              "previous_button_text": "Tilbage",
              "next_button_text": "Indsend"
            }
          },
          "2": {
            "0": {
              "text": "Tak for din feedback, som vi sætter stor pris på!\n\nVi håber at se dig igen snart!"
            },
            "1": {
              "text": "Opdag vores seneste innovationer!"
            }
          }
        }
      },
      "hu": {
        "elements": {
          "0": {
            "0": {
              "text": "Örömünkre szolgált, hogy üdvözölhettük!"
            },
            "1": {
              "label": "Ajánlaná a {company_name}-t barátainak és családtagjainak?",
              "na_label": "N/A",
              "legend": {
                "low_score": "0 - Egyáltalán nem",
                "high_score": "10 - Igen, feltétlenül"
              },
              "rating": {
                "0": "0",
                "1": "1",
                "2": "2",
                "3": "3",
                "4": "4",
                "5": "5",
                "6": "6",
                "7": "7",
                "8": "8",
                "9": "9",
                "10": "10"
              }
            },
            "2": {
              "label": "Mondjon többet a tapasztalatairól",
              "placeholder": "Írjon ide"
            },
            "3": {
              "previous_button_text": "Vissza",
              "next_button_text": "Beküld"
            }
          },
          "2": {
            "0": {
              "text": "Köszönjük az értékes visszajelzését!\n\nReméljük, hogy hamarosan viszontlátjuk!"
            },
            "1": {
              "text": "Fedezze fel legújabb innovációinkat!"
            }
          }
        }
      },
      "ro": {
        "elements": {
          "0": {
            "0": {
              "text": "A fost o plăcere să vă urăm bun venit!"
            },
            "1": {
              "label": "Ați recomanda {company_name} prietenilor și familiei dumneavoastră?",
              "na_label": "N/A",
              "legend": {
                "low_score": "0 - În niciun caz",
                "high_score": "10 - Da, cu siguranță"
              },
              "rating": {
                "0": "0",
                "1": "1",
                "2": "2",
                "3": "3",
                "4": "4",
                "5": "5",
                "6": "6",
                "7": "7",
                "8": "8",
                "9": "9",
                "10": "10"
              }
            },
            "2": {
              "label": "Spuneți-ne mai multe despre experiența dumneavoastră",
              "placeholder": "Scrieți aici"
            },
            "3": {
              "previous_button_text": "Înapoi",
              "next_button_text": "Trimiteți"
            }
          },
          "2": {
            "0": {
              "text": "Vă mulțumim pentru feedback-ul dumneavoastră care este foarte apreciat!\n\nSperăm să vă revedem curând!"
            },
            "1": {
              "text": "Descoperiți cele mai noi inovații ale noastre!"
            }
          }
        }
      },
      "ar": {
        "elements": {
          "0": {
            "0": {
              "text": "!لقد كان من دواعي سرورنا الترحيب بكم"
            },
            "1": {
              "label": "هل ستوصي أصدقائك وعائلتك باستخدام {company_name}?",
              "na_label": "N/A",
              "legend": {
                "low_score": "0 - بالتأكيد لا",
                "high_score": "10 - نعم، بالتأكيد"
              },
              "rating": {
                "0": "0",
                "1": "1",
                "2": "2",
                "3": "3",
                "4": "4",
                "5": "5",
                "6": "6",
                "7": "7",
                "8": "8",
                "9": "9",
                "10": "10"
              }
            },
            "2": {
              "label": "أخبرنا المزيد عن تجربتك",
              "placeholder": "اكتب هنا"
            },
            "3": {
              "previous_button_text": "رجوع",
              "next_button_text": "يُقدِّم"
            }
          },
          "2": {
            "0": {
              "text": "!نأمل أن نراك مرة أخرى قريباً\n\n!شكراً على ملاحظاتك القيمة"
            },
            "1": {
              "text": "!اكتشف أحدث ابتكاراتنا"
            }
          }
        }
      },
      "he": {
        "elements": {
          "0": {
            "0": {
              "text": "!היה לנו לעונג לארח אתכם"
            },
            "1": {
              "label": "?האם תמליץ/י על {company_name} לחברים ומשפחה",
              "na_label": "N/A",
              "legend": {
                "low_score": "0 - בהחלט לא",
                "high_score": "10 - כן, בהחלט"
              },
              "rating": {
                "0": "0",
                "1": "1",
                "2": "2",
                "3": "3",
                "4": "4",
                "5": "5",
                "6": "6",
                "7": "7",
                "8": "8",
                "9": "9",
                "10": "10"
              }
            },
            "2": {
              "label": "ספר/י לנו עוד על החוויה שלך",
              "placeholder": "כתוב/י כאן"
            },
            "3": {
              "previous_button_text": "חזרה",
              "next_button_text": "לְהַגִישׁ"
            }
          },
          "2": {
            "0": {
              "text": "!אנו מקווים לראותך שוב בקרוב\n\n!תודה על המשוב שלך שמוערך מאוד"
            },
            "1": {
              "text": "!גלה את החידושים האחרונים שלנו"
            }
          }
        }
      },
      "tur": {
        "elements": {
          "0": {
            "0": {
              "text": "Sizi ağırlamak bizim için bir zevkti!"
            },
            "1": {
              "label": "{comany_name}'u arkadaşlarınıza ve ailenize tavsiye eder misiniz?",
              "na_label": "N/A",
              "legend": {
                "low_score": "0 - Kesinlikle hayır",
                "high_score": "10 - Evet, kesinlikle"
              },
              "rating": {
                "0": "0",
                "1": "1",
                "2": "2",
                "3": "3",
                "4": "4",
                "5": "5",
                "6": "6",
                "7": "7",
                "8": "8",
                "9": "9",
                "10": "10"
              }
            },
            "2": {
              "label": "Deneyiminiz hakkında daha fazla bilgi verin",
              "placeholder": "Buraya yazın"
            },
            "3": {
              "previous_button_text": "Geri",
              "next_button_text": "Göndermek"
            }
          },
          "2": {
            "0": {
              "text": "Değerli geri bildiriminiz için teşekkür ederiz!!\n\nSizi tekrar görmeyi umuyoruz!"
            },
            "1": {
              "text": "En son yeniliklerimizi keşfedin!"
            }
          }
        }
      },
      _languages: []
    },

    csat: {
      "en": {
        "elements": {
          "0": {
            "0": {
              "text": "It has been a pleasure to welcome you!"
            },
            "1": {
              "label": "How satisfied are you with your overall experience with {company_name}?",
              "na_label": "N/A",
              "legend": {
                "low_score": "1 - Very dissatisfied",
                "high_score": "5 - Very satisfied"
              },
              "rating": {
                "1": "1",
                "2": "2",
                "3": "3",
                "4": "4",
                "5": "5"
              }
            },
            "2": {
              "label": "Tell us more about your experience",
              "placeholder": "Write here"
            },
            "3": {
              "previous_button_text": "Back",
              "next_button_text": "Submit"
            }
          },
          "2": {
            "0": {
              "text": "Thank you for your feedback which is much appreciated!\n\nWe hope to see you again soon!"
            },
            "1": {
              "text": "Discover our latest innovations!"
            }
          }
        }
      },
      "fr": {
        "elements": {
          "0": {
            "0": {
              "text": "Ce fut un plaisir de vous accueillir !"
            },
            "1": {
              "label": "Quel est votre niveau de satisfaction concernant votre expérience globale avec {company_name} ?",
              "na_label": "N/A",
              "legend": {
                "low_score": "1 - Très insatisfait",
                "high_score": "5 - Très satisfait"
              },
              "rating": {
                "1": "1",
                "2": "2",
                "3": "3",
                "4": "4",
                "5": "5"
              }
            },
            "2": {
              "label": "Parlez nous de votre votre expérience",
              "placeholder": "Écrivez ici"
            },
            "3": {
              "previous_button_text": "Retour",
              "next_button_text": "Soumettre"
            }
          },
          "2": {
            "0": {
              "text": "Merci pour votre retour qui nous est très précieux !\n\nNous espérons vous revoir bientôt !"
            },
            "1": {
              "text": "Découvrez nos dernières innovations !"
            }
          }
        }
      },
      "es": {
        "elements": {
          "0": {
            "0": {
              "text": "¡Ha sido un placer darle la bienvenida!"
            },
            "1": {
              "label": "¿Cuál es su nivel de satisfacción con su experiencia global con {company_name}?",
              "na_label": "N/A",
              "legend": {
                "low_score": "1 - Muy insatisfecho",
                "high_score": "5 - Muy satisfecho"
              },
              "rating": {
                "1": "1",
                "2": "2",
                "3": "3",
                "4": "4",
                "5": "5"
              }
            },
            "2": {
              "label": "Cuéntenos más sobre su experiencia",
              "placeholder": "Escriba aquí"
            },
            "3": {
              "previous_button_text": "Atrás",
              "next_button_text": "Entregar"
            }
          },
          "2": {
            "0": {
              "text": "¡Gracias por sus comentarios que son muy apreciados!\n\n¡Esperamos verle pronto!"
            },
            "1": {
              "text": "¡Descubra nuestras últimas innovaciones!"
            }
          }
        }
      },
      "nl": {
        "elements": {
          "0": {
            "0": {
              "text": "Het was een genoegen u te mogen verwelkomen!"
            },
            "1": {
              "label": "Hoe tevreden bent u over uw algemene ervaring met {company_name}?",
              "na_label": "N/A",
              "legend": {
                "low_score": "1 - Zeer ontevreden",
                "high_score": "5 - Zeer tevreden"
              },
              "rating": {
                "1": "1",
                "2": "2",
                "3": "3",
                "4": "4",
                "5": "5"
              }
            },
            "2": {
              "label": "Vertel ons meer over uw ervaring",
              "placeholder": "Schrijf hier"
            },
            "3": {
              "previous_button_text": "Terug",
              "next_button_text": "Indienen"
            }
          },
          "2": {
            "0": {
              "text": "Hartelijk dank voor uw waardevolle feedback!\n\nWe hopen u snel weer te zien!"
            },
            "1": {
              "text": "Ontdek onze nieuwste innovaties!"
            }
          }
        }
      },
      "zh": {
        "elements": {
          "0": {
            "0": {
              "text": "很高兴迎接您！"
            },
            "1": {
              "label": "您对{company_name}的整体体验满意度如何？",
              "na_label": "N/A",
              "legend": {
                "low_score": "1 - 非常不满意",
                "high_score": "5 - 非常满意"
              },
              "rating": {
                "1": "1",
                "2": "2",
                "3": "3",
                "4": "4",
                "5": "5"
              }
            },
            "2": {
              "label": "请告诉我们更多关于您的体验",
              "placeholder": "在此填写"
            },
            "3": {
              "previous_button_text": "返回",
              "next_button_text": "提交"
            }
          },
          "2": {
            "0": {
              "text": "感谢您宝贵的反馈！\n\n希望很快再见到您！"
            },
            "1": {
              "text": "探索我们的最新创新！"
            }
          }
        }
      },
      "zht": {
        "elements": {
          "0": {
            "0": {
              "text": "很高興迎接您！"
            },
            "1": {
              "label": "您對{company_name}的整體體驗滿意度如何？",
              "na_label": "N/A",
              "legend": {
                "low_score": "1 - 非常不滿意會",
                "high_score": "5 - 非常滿意"
              },
              "rating": {
                "1": "1",
                "2": "2",
                "3": "3",
                "4": "4",
                "5": "5"
              }
            },
            "2": {
              "label": "請告訴我們更多關於您的體驗",
              "placeholder": "在此填寫"
            },
            "3": {
              "previous_button_text": "回傳",
              "next_button_text": "提交"
            }
          },
          "2": {
            "0": {
              "text": "感謝您寶貴的反饋！\n\n希望很快再見到您！"
            },
            "1": {
              "text": "探索我們的最新創新！"
            }
          }
        }
      },
      "ko": {
        "elements": {
          "0": {
            "0": {
              "text": "환영해 드릴 수 있어 기뻤습니다!"
            },
            "1": {
              "label": "{company_name}의 전반적인 경험에 대해 얼마나 만족하셨습니까?",
              "na_label": "N/A",
              "legend": {
                "low_score": "1 - 매우 불만족",
                "high_score": "5 - 매우 만족"
              },
              "rating": {
                "1": "1",
                "2": "2",
                "3": "3",
                "4": "4",
                "5": "5"
              }
            },
            "2": {
              "label": "귀하의 경험에 대해 더 자세히 말씀해 주세요",
              "placeholder": "여기에 작성하세요"
            },
            "3": {
              "previous_button_text": "뒤로",
              "next_button_text": "제출하다"
            }
          },
          "2": {
            "0": {
              "text": "귀중한 피드백 감사합니다!\n\n곧 다시 뵙기를 희망합니다!"
            },
            "1": {
              "text": "최신 혁신을 확인해보세요!"
            }
          }
        }
      },
      "ja": {
        "elements": {
          "0": {
            "0": {
              "text": "お迎えできて光栄でした！"
            },
            "1": {
              "label": "{company_name}のご利用全般についてどの程度ご満足いただけましたか？",
              "na_label": "N/A",
              "legend": {
                "low_score": "1 - 非常に不満",
                "high_score": "5 - 非常に満足"
              },
              "rating": {
                "1": "1",
                "2": "2",
                "3": "3",
                "4": "4",
                "5": "5"
              }
            },
            "2": {
              "label": "ご体験についてさらにお聞かせください",
              "placeholder": "こちらにご記入ください"
            },
            "3": {
              "previous_button_text": "戻る",
              "next_button_text": "提出する"
            }
          },
          "2": {
            "0": {
              "text": "貴重なフィードバックをありがとうございます！\n\nまたのお越しをお待ちしております！"
            },
            "1": {
              "text": "最新のイノベーションをご覧ください！"
            }
          }
        }
      },
      "th": {
        "elements": {
          "0": {
            "0": {
              "text": "ยินดีที่ได้ต้อนรับคุณ!"
            },
            "1": {
              "label": "คุณพึงพอใจกับประสบการณ์โดยรวมกับ {company_name} มากน้อยเพียงใด??",
              "na_label": "N/A",
              "legend": {
                "low_score": "1 - ไม่พอใจมาก",
                "high_score": "5 - พอใจมาก"
              },
              "rating": {
                "1": "1",
                "2": "2",
                "3": "3",
                "4": "4",
                "5": "5"
              }
            },
            "2": {
              "label": "บอกเราเพิ่มเติมเกี่ยวกับประสบการณ์ของคุณ",
              "placeholder": "เขียนที่นี่"
            },
            "3": {
              "previous_button_text": "ย้อนกลับ",
              "next_button_text": "ส่ง"
            }
          },
          "2": {
            "0": {
              "text": "ขอบคุณสำหรับข้อเสนอแนะที่มีค่าของคุณ!\n\nหวังว่าจะได้พบคุณอีกเร็วๆ นี้!"
            },
            "1": {
              "text": "ค้นพบนวัตกรรมล่าสุดของเรา!"
            }
          }
        }
      },
      "de": {
        "elements": {
          "0": {
            "0": {
              "text": "Es war uns eine Freude, Sie willkommen zu heißen!"
            },
            "1": {
              "label": "Wie zufrieden sind Sie mit Ihrer Gesamterfahrung mit {company_name}?",
              "na_label": "N/A",
              "legend": {
                "low_score": "1 - Sehr unzufrieden",
                "high_score": "5 - Sehr zufrieden"
              },
              "rating": {
                "1": "1",
                "2": "2",
                "3": "3",
                "4": "4",
                "5": "5"
              }
            },
            "2": {
              "label": "Erzählen Sie uns mehr über Ihre Erfahrung",
              "placeholder": "Hier schreiben"
            },
            "3": {
              "previous_button_text": "Zurück",
              "next_button_text": "Einreichen"
            }
          },
          "2": {
            "0": {
              "text": "Vielen Dank für Ihr wertvolles Feedback!\n\nWir hoffen, Sie bald wiederzusehen!"
            },
            "1": {
              "text": "Entdecken Sie unsere neuesten Innovationen!"
            }
          }
        }
      },
      "vi": {
        "elements": {
          "0": {
            "0": {
              "text": "Thật vui được chào đón bạn!"
            },
            "1": {
              "label": "Bạn hài lòng như thế nào với trải nghiệm tổng thể với {company_name}?",
              "na_label": "N/A",
              "legend": {
                "low_score": "1 - Rất không hài lòng",
                "high_score": "5 - Rất hài lòng"
              },
              "rating": {
                "1": "1",
                "2": "2",
                "3": "3",
                "4": "4",
                "5": "5"
              }
            },
            "2": {
              "label": "Hãy chia sẻ thêm về trải nghiệm của bạn",
              "placeholder": "Viết tại đây"
            },
            "3": {
              "previous_button_text": "Quay lại",
              "next_button_text": "Nộp"
            }
          },
          "2": {
            "0": {
              "text": "MCảm ơn bạn rất nhiều vì phản hồi quý báu!\n\nChúng tôi hy vọng sớm gặp lại bạn!"
            },
            "1": {
              "text": "Khám phá những đổi mới mới nhất của chúng tôi!"
            }
          }
        }
      },
      "id": {
        "elements": {
          "0": {
            "0": {
              "text": "Senang dapat menyambut Anda!"
            },
            "1": {
              "label": "Seberapa puas Anda dengan pengalaman keseluruhan dengan {company_name}?",
              "na_label": "N/A",
              "legend": {
                "low_score": "1 - Sangat tidak puas",
                "high_score": "5 - Sangat puas"
              },
              "rating": {
                "1": "1",
                "2": "2",
                "3": "3",
                "4": "4",
                "5": "5"
              }
            },
            "2": {
              "label": "Ceritakan lebih lanjut tentang pengalaman Anda",
              "placeholder": "Tulis di sini"
            },
            "3": {
              "previous_button_text": "Kembali",
              "next_button_text": "Kirim"
            }
          },
          "2": {
            "0": {
              "text": "Terima kasih atas umpan balik Anda yang sangat berharga!\n\nKami harap dapat bertemu Anda lagi segera!"
            },
            "1": {
              "text": "Temukan inovasi terbaru kami!"
            }
          }
        }
      },
      "mn": {
        "elements": {
          "0": {
            "0": {
              "text": "Таныг угтан авч байгаадаа баяртай байна!"
            },
            "1": {
              "label": "Та {company_name}-той харьцсан ерөнхий туршлагадаа хэр сэтгэл ханамжтай байна?",
              "na_label": "N/A",
              "legend": {
                "low_score": "1 - Маш сэтгэл ханамжгүй",
                "high_score": "5 - Маш сэтгэл ханамжтай"
              },
              "rating": {
                "1": "1",
                "2": "2",
                "3": "3",
                "4": "4",
                "5": "5"
              }
            },
            "2": {
              "label": "Өөрийн туршлагаас дэлгэрэнгүй хуваалцана уу",
              "placeholder": "Энд бичнэ үү"
            },
            "3": {
              "previous_button_text": "Буцах",
              "next_button_text": "Илгээх"
            }
          },
          "2": {
            "0": {
              "text": "Таны үнэтэй санал хүсэлтэд маш их баярлалаа!\n\nТантай удахгүй дахин уулзахыг найдаж байна!"
            },
            "1": {
              "text": "Манай шинэ инновациудыг нээгээрэй!"
            }
          }
        }
      },
      "it": {
        "elements": {
          "0": {
            "0": {
              "text": "È stato un piacere darle il benvenuto!"
            },
            "1": {
              "label": "Quanto è soddisfatto della sua esperienza complessiva con {company_name}?",
              "na_label": "N/A",
              "legend": {
                "low_score": "1 - Molto insoddisfatto",
                "high_score": "5 - Molto soddisfatto"
              },
              "rating": {
                "1": "1",
                "2": "2",
                "3": "3",
                "4": "4",
                "5": "5"
              }
            },
            "2": {
              "label": "Ci racconti di più sulla sua esperienza",
              "placeholder": "Scriva qui"
            },
            "3": {
              "previous_button_text": "Indietro",
              "next_button_text": "Invia"
            }
          },
          "2": {
            "0": {
              "text": "Grazie per il suo prezioso feedback!\n\nSperiamo di rivederla presto!"
            },
            "1": {
              "text": "Scopra le nostre ultime innovazioni!"
            }
          }
        }
      },
      "pt": {
        "elements": {
          "0": {
            "0": {
              "text": "Foi um prazer recebê-lo!"
            },
            "1": {
              "label": "Qual é o seu nível de satisfação com a sua experiência global com a {company_name}?",
              "na_label": "N/A",
              "legend": {
                "low_score": "1 - Muito insatisfeito",
                "high_score": "5 - Muito satisfeito"
              },
              "rating": {
                "1": "1",
                "2": "2",
                "3": "3",
                "4": "4",
                "5": "5"
              }
            },
            "2": {
              "label": "Conte-nos mais sobre a sua experiência",
              "placeholder": "Escreva aqui"
            },
            "3": {
              "previous_button_text": "Voltar",
              "next_button_text": "Submeter"
            }
          },
          "2": {
            "0": {
              "text": "Obrigado pelo seu feedback que é muito apreciado!\n\nEsperamos vê-lo novamente em breve!"
            },
            "1": {
              "text": "Descubra as nossas últimas inovações!"
            }
          }
        }
      },
      "pt_br": {
        "elements": {
          "0": {
            "0": {
              "text": "Foi um prazer receber você!"
            },
            "1": {
              "label": "Qual é seu nível de satisfação com sua experiência geral com a {company_name}?",
              "na_label": "N/A",
              "legend": {
                "low_score": "1 - Muito insatisfeito",
                "high_score": "5 - Muito satisfeito"
              },
              "rating": {
                "1": "1",
                "2": "2",
                "3": "3",
                "4": "4",
                "5": "5"
              }
            },
            "2": {
              "label": "Conte-nos mais sobre sua experiência",
              "placeholder": "Escreva aqui"
            },
            "3": {
              "previous_button_text": "Voltar",
              "next_button_text": "Enviar"
            }
          },
          "2": {
            "0": {
              "text": "Obrigado pelo seu feedback que é muito importante para nós!\n\nEsperamos ver você novamente em breve!"
            },
            "1": {
              "text": "Descubra as nossas últimas inovações!"
            }
          }
        }
      },
      "es_mx": {
        "elements": {
          "0": {
            "0": {
              "text": "¡Ha sido un placer darle la bienvenida!"
            },
            "1": {
              "label": "¿Qué tan satisfecho está con su experiencia general con {company_name}?",
              "na_label": "N/A",
              "legend": {
                "low_score": "1 - Muy insatisfecho",
                "high_score": "5 - Muy satisfecho"
              },
              "rating": {
                "1": "1",
                "2": "2",
                "3": "3",
                "4": "4",
                "5": "5"
              }
            },
            "2": {
              "label": "Cuéntenos más sobre su experiencia",
              "placeholder": "Escriba aquí"
            },
            "3": {
              "previous_button_text": "Atrás",
              "next_button_text": "Siguiente"
            }
          },
          "2": {
            "0": {
              "text": "¡Gracias por sus comentarios que son muy apreciados!\n\n¡Esperamos verle pronto!"
            },
            "1": {
              "text": "¡Descubra nuestras últimas innovaciones!"
            }
          }
        }
      },
      "fr_ca": {
        "elements": {
          "0": {
            "0": {
              "text": "Ce fut un plaisir de vous accueillir !"
            },
            "1": {
              "label": "Quel est votre niveau de satisfaction concernant votre expérience globale avec {company_name} ?",
              "na_label": "N/A",
              "legend": {
                "low_score": "1 - Très insatisfait",
                "high_score": "5 - Très satisfait"
              },
              "rating": {
                "1": "1",
                "2": "2",
                "3": "3",
                "4": "4",
                "5": "5"
              }
            },
            "2": {
              "label": "Parlez nous de votre votre expérience",
              "placeholder": "Écrivez ici"
            },
            "3": {
              "previous_button_text": "Retour",
              "next_button_text": "Soumettre"
            }
          },
          "2": {
            "0": {
              "text": "Merci pour votre retour qui nous est très précieux !\n\nNous espérons vous revoir bientôt !"
            },
            "1": {
              "text": "Découvrez nos dernières innovations !"
            }
          }
        }
      },
      "ru": {
        "elements": {
          "0": {
            "0": {
              "text": "Было приятно приветствовать вас!"
            },
            "1": {
              "label": "Насколько вы удовлетворены своим общим опытом работы с {company_name}?",
              "na_label": "N/A",
              "legend": {
                "low_score": "1 - Крайне недоволен",
                "high_score": "5 - Полностью удовлетворен"
              },
              "rating": {
                "1": "1",
                "2": "2",
                "3": "3",
                "4": "4",
                "5": "5"
              }
            },
            "2": {
              "label": "Расскажите подробнее о вашем опыте",
              "placeholder": "Напишите здесь"
            },
            "3": {
              "previous_button_text": "Назад",
              "next_button_text": "Представлять на рассмотрение"
            }
          },
          "2": {
            "0": {
              "text": "Спасибо за ваш отзыв, который мы высоко ценим!\n\nНадеемся увидеть вас снова!"
            },
            "1": {
              "text": "Откройте для себя наши последние инновации!"
            }
          }
        }
      },
      "pl": {
        "elements": {
          "0": {
            "0": {
              "text": "Było nam miło Państwa gościć!"
            },
            "1": {
              "label": "Jak oceniają Państwo swoje ogólne doświadczenie z {company_name}?",
              "na_label": "N/A",
              "legend": {
                "low_score": "1 - Bardzo niezadowolony",
                "high_score": "5 - Bardzo zadowolony"
              },
              "rating": {
                "1": "1",
                "2": "2",
                "3": "3",
                "4": "4",
                "5": "5"
              }
            },
            "2": {
              "label": "Prosimy o więcej szczegółów dotyczących Państwa doświadczenia",
              "placeholder": "Proszę pisać tutaj"
            },
            "3": {
              "previous_button_text": "Wstecz",
              "next_button_text": "Składać"
            }
          },
          "2": {
            "0": {
              "text": "Dziękujemy za Państwa cenną opinię!\n\nMamy nadzieję zobaczyć Państwa ponownie!"
            },
            "1": {
              "text": "Odkryj nasze najnowsze innowacje!"
            }
          }
        }
      },
      "cs": {
        "elements": {
          "0": {
            "0": {
              "text": "Bylo nám potěšením vás přivítat!"
            },
            "1": {
              "label": "Jak jste spokojeni s celkovou zkušeností s {company_name}?",
              "na_label": "N/A",
              "legend": {
                "low_score": "1 - Velmi nespokojen",
                "high_score": "5 - Velmi spokojen"
              },
              "rating": {
                "1": "1",
                "2": "2",
                "3": "3",
                "4": "4",
                "5": "5"
              }
            },
            "2": {
              "label": "Řekněte nám více o vaší zkušenosti",
              "placeholder": "Pište zde"
            },
            "3": {
              "previous_button_text": "Zpět",
              "next_button_text": "Předložit"
            }
          },
          "2": {
            "0": {
              "text": "Děkujeme za vaši zpětnou vazbu, které si velmi vážíme!\n\nDoufáme, že se brzy uvidíme!"
            },
            "1": {
              "text": "Objevte naše nejnovější inovace!"
            }
          }
        }
      },
      "el": {
        "elements": {
          "0": {
            "0": {
              "text": "Ήταν χαρά μας που σας υποδεχτήκαμε!"
            },
            "1": {
              "label": "Πόσο ικανοποιημένοι είστε με τη συνολική σας εμπειρία με την {company_name};",
              "na_label": "N/A",
              "legend": {
                "low_score": "1 - Πολύ δυσαρεστημένος",
                "high_score": "5 - Πολύ ικανοποιημένος"
              },
              "rating": {
                "1": "1",
                "2": "2",
                "3": "3",
                "4": "4",
                "5": "5"
              }
            },
            "2": {
              "label": "Πείτε μας περισσότερα για την εμπειρία σας",
              "placeholder": "Γράψτε εδώ"
            },
            "3": {
              "previous_button_text": "Πίσω",
              "next_button_text": "Υποτάσσομαι"
            }
          },
          "2": {
            "0": {
              "text": "Σας ευχαριστούμε για τα σχόλιά σας που εκτιμούμε ιδιαίτερα!\n\nΕλπίζουμε να σας δούμε ξανά σύντομα!"
            },
            "1": {
              "text": "Ανακαλύψτε τις τελευταίες καινοτομίες μας!"
            }
          }
        }
      },
      "fi": {
        "elements": {
          "0": {
            "0": {
              "text": "Oli ilo toivottaa teidät tervetulleeksi!"
            },
            "1": {
              "label": "Kuinka tyytyväinen olette kokonaiskokemukseenne {company_name}n kanssa?",
              "na_label": "N/A",
              "legend": {
                "low_score": "1 - Erittäin tyytymätön",
                "high_score": "5 - Erittäin tyytyväinen"
              },
              "rating": {
                "1": "1",
                "2": "2",
                "3": "3",
                "4": "4",
                "5": "5"
              }
            },
            "2": {
              "label": "Kertokaa lisää kokemuksestanne",
              "placeholder": "Kirjoittakaa tähän"
            },
            "3": {
              "previous_button_text": "Takaisin",
              "next_button_text": "Lähetä"
            }
          },
          "2": {
            "0": {
              "text": "Kiitos palautteestanne, jota arvostamme suuresti!\n\nToivomme näkevämme teidät pian uudelleen!"
            },
            "1": {
              "text": "Tutustu uusimpiin innovaatioihimme!"
            }
          }
        }
      },
      "sv": {
        "elements": {
          "0": {
            "0": {
              "text": "Det har varit ett nöje att välkomna dig!"
            },
            "1": {
              "label": "Hur nöjd är du med din övergripande upplevelse av {company_name}?",
              "na_label": "N/A",
              "legend": {
                "low_score": "1 - Mycket missnöjd",
                "high_score": "5 - Mycket nöjd"
              },
              "rating": {
                "1": "1",
                "2": "2",
                "3": "3",
                "4": "4",
                "5": "5"
              }
            },
            "2": {
              "label": "Berätta mer om din upplevelse",
              "placeholder": "Skriv här"
            },
            "3": {
              "previous_button_text": "Späť",
              "next_button_text": "Överlämna"
            }
          },
          "2": {
            "0": {
              "text": "Tack för din värdefulla feedback!\n\nVi hoppas få se dig snart igen!"
            },
            "1": {
              "text": "Upptäck våra senaste innovationer!"
            }
          }
        }
      },
      "no": {
        "elements": {
          "0": {
            "0": {
              "text": "Det har vært en glede å ønske deg velkommen!"
            },
            "1": {
              "label": "Hvor fornøyd er du med din generelle opplevelse med {company_name}?",
              "na_label": "N/A",
              "legend": {
                "low_score": "1 - Svært misfornøyd",
                "high_score": "5 - Svært fornøyd"
              },
              "rating": {
                "1": "1",
                "2": "2",
                "3": "3",
                "4": "4",
                "5": "5"
              }
            },
            "2": {
              "label": "Fortell oss mer om din opplevelse",
              "placeholder": "Skriv her"
            },
            "3": {
              "previous_button_text": "Tilbake",
              "next_button_text": "Send inn"
            }
          },
          "2": {
            "0": {
              "text": "Takk for din tilbakemelding som er høyt verdsatt!\n\nVi håper å se deg igjen snart!"
            },
            "1": {
              "text": "Oppdag våre nyeste innovasjoner!"
            }
          }
        }
      },
      "da": {
        "elements": {
          "0": {
            "0": {
              "text": "Det har været en fornøjelse at byde dig velkommen!"
            },
            "1": {
              "label": "Hvor tilfreds er du med din samlede oplevelse med {company_name}?",
              "na_label": "N/A",
              "legend": {
                "low_score": "1 - Meget utilfreds",
                "high_score": "5 - Meget tilfreds"
              },
              "rating": {
                "1": "1",
                "2": "2",
                "3": "3",
                "4": "4",
                "5": "5"
              }
            },
            "2": {
              "label": "Fortæl os mere om din oplevelse",
              "placeholder": "Skriv her"
            },
            "3": {
              "previous_button_text": "Tilbage",
              "next_button_text": "Indsend"
            }
          },
          "2": {
            "0": {
              "text": "Tak for din feedback, som vi sætter stor pris på!\n\nVi håber at se dig igen snart!"
            },
            "1": {
              "text": "Opdag vores seneste innovationer!"
            }
          }
        }
      },
      "hu": {
        "elements": {
          "0": {
            "0": {
              "text": "Örömünkre szolgált, hogy üdvözölhettük!"
            },
            "1": {
              "label": "Mennyire elégedett a {company_name}-jal kapcsolatos általános tapasztalataival?",
              "na_label": "N/A",
              "legend": {
                "low_score": "1 - Nagyon elégedetlen",
                "high_score": "5 - Nagyon elégedett"
              },
              "rating": {
                "1": "1",
                "2": "2",
                "3": "3",
                "4": "4",
                "5": "5"
              }
            },
            "2": {
              "label": "Mondjon többet a tapasztalatairól",
              "placeholder": "Írjon ide"
            },
            "3": {
              "previous_button_text": "Vissza",
              "next_button_text": "Beküld"
            }
          },
          "2": {
            "0": {
              "text": "Köszönjük az értékes visszajelzését!\n\nReméljük, hogy hamarosan viszontlátjuk!"
            },
            "1": {
              "text": "Fedezze fel legújabb innovációinkat!"
            }
          }
        }
      },
      "ro": {
        "elements": {
          "0": {
            "0": {
              "text": "A fost o plăcere să vă urăm bun venit!"
            },
            "1": {
              "label": "Cât de mulțumit sunteți de experiența generală cu {company_name}?",
              "na_label": "N/A",
              "legend": {
                "low_score": "1 - Foarte nemulțumit",
                "high_score": "5 - Foarte mulțumit"
              },
              "rating": {
                "1": "1",
                "2": "2",
                "3": "3",
                "4": "4",
                "5": "5"
              }
            },
            "2": {
              "label": "Spuneți-ne mai multe despre experiența dumneavoastră",
              "placeholder": "Scrieți aici"
            },
            "3": {
              "previous_button_text": "Înapoi",
              "next_button_text": "Trimiteți"
            }
          },
          "2": {
            "0": {
              "text": "Vă mulțumim pentru feedback-ul dumneavoastră care este foarte apreciat!\n\nSperăm să vă revedem curând!"
            },
            "1": {
              "text": "Descoperiți cele mai noi inovații ale noastre!"
            }
          }
        }
      },
      "ar": {
        "elements": {
          "0": {
            "0": {
              "text": "!لقد كان من دواعي سرورنا الترحيب بكم"
            },
            "1": {
              "label": "ما مدى رضاك عن تجربتك الشاملة مع {company_name}؟",
              "na_label": "N/A",
              "legend": {
                "low_score": "1 - غير راضٍ جداً",
                "high_score": "5 - راضٍ جداً"
              },
              "rating": {
                "1": "1",
                "2": "2",
                "3": "3",
                "4": "4",
                "5": "5"
              }
            },
            "2": {
              "label": "أخبرنا المزيد عن تجربتك",
              "placeholder": "اكتب هنا"
            },
            "3": {
              "previous_button_text": "رجوع",
              "next_button_text": "يُقدِّم"
            }
          },
          "2": {
            "0": {
              "text": "!نأمل أن نراك مرة أخرى قريباً\n\n!شكراً على ملاحظاتك القيمة"
            },
            "1": {
              "text": "!اكتشف أحدث ابتكاراتنا"
            }
          }
        }
      },
      "he": {
        "elements": {
          "0": {
            "0": {
              "text": "!היה לנו לעונג לארח אתכם"
            },
            "1": {
              "label": "מה מידת שביעות רצונך מהחוויה הכוללת שלך עם {company_name}?",
              "na_label": "N/A",
              "legend": {
                "low_score": "1 - מאוד לא מרוצה",
                "high_score": "5 - מאוד מרוצה"
              },
              "rating": {
                "1": "1",
                "2": "2",
                "3": "3",
                "4": "4",
                "5": "5"
              }
            },
            "2": {
              "label": "ספר/י לנו עוד על החוויה שלך",
              "placeholder": "כתוב/י כאן"
            },
            "3": {
              "previous_button_text": "חזרה",
              "next_button_text": "לְהַגִישׁ"
            }
          },
          "2": {
            "0": {
              "text": "!אנו מקווים לראותך שוב בקרוב\n\n!תודה על המשוב שלך שמוערך מאוד"
            },
            "1": {
              "text": "!גלה את החידושים האחרונים שלנו"
            }
          }
        }
      },
      "tur": {
        "elements": {
          "0": {
            "0": {
              "text": "Sizi ağırlamak bizim için bir zevkti!"
            },
            "1": {
              "label": "{company_name} ile genel deneyiminizden ne kadar memnunsunuz?",
              "na_label": "N/A",
              "legend": {
                "low_score": "1 - Hiç memnun değilim",
                "high_score": "5 - Çok memnunum"
              },
              "rating": {
                "1": "1",
                "2": "2",
                "3": "3",
                "4": "4",
                "5": "5"
              }
            },
            "2": {
              "label": "Deneyiminiz hakkında daha fazla bilgi verin",
              "placeholder": "Buraya yazın"
            },
            "3": {
              "previous_button_text": "Geri",
              "next_button_text": "Göndermek"
            }
          },
          "2": {
            "0": {
              "text": "Değerli geri bildiriminiz için teşekkür ederiz!!\n\nSizi tekrar görmeyi umuyoruz!"
            },
            "1": {
              "text": "En son yeniliklerimizi keşfedin!"
            }
          }
        }
      },
      _languages: []
    },
    custom: {
      fr: { elements: {}, _languages: [] },
      en: { elements: {}, _languages: [] }
    }
  };
});