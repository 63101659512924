define('due-dashboard/components/navbar-link', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  exports['default'] = Component.extend({
    tagName: 'li',
    classNames: ['navbar-link'],
    classNamesBindings: ['hidden', 'data-content', 'version'],
    currentLanguage: computed.alias('currentAccount.content.language.tag'),
    hidden: false,

    currentAccount: _ember['default'].inject.service(),
    isCompanyLoaded: false,

    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;

      this._super.apply(this, arguments);
      _ember['default'].RSVP.all([this.get('currentAccount.content.company'), this.get('currentAccount.content.profile'), this.get('currentAccount.content.rights')]).then(function () {
        _this.set('isCompanyLoaded', true);
      });
    },

    computedText: computed('text', function () {
      var textString = this.get('text').string || this.get('text');
      if (typeof textString === 'string') {
        return textString.startsWith('menu.') ? this.get('i18n').t(textString) : textString;
      } else {
        var text = this.get('text');
        return text[this.get('currentLanguage')] || text[this.get('currentAccount.content.company.language.tag')];
      }
    })
  });
});