define('due-dashboard/components/v2/libraries/surveys/navbar/translations/-overview', ['exports', 'ember'], function (exports, _ember) {
  var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

  function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var copy = _ember['default'].copy;
  exports['default'] = Component.extend({
    classNames: ['survey-translations'],
    actionDropdownIsOpen: false,
    editMainLanguageAlertIsOpen: false,
    importTranslationsAlertIsOpen: false,
    selectedDefaultLanguage: null,
    translationsFile: '',
    unsavedChanges: false,

    init: function init() {
      this._super.apply(this, arguments);
      this.initialTranslationsCopy();
    },

    didUpdateAttrs: function didUpdateAttrs() {
      this.initialTranslationsCopy();
    },

    initialTranslationsCopy: function initialTranslationsCopy() {
      this.set('savedTranslations', copy(this.get('surveyDraft.translations'), true));
      this.set('savedHtmlMeta', copy(this.get('surveyDraft.html_meta'), true));
    },

    availableLanguages: computed('surveyDraft.translations', 'surveyDraft.main_language', function () {
      var dashboardLanguages = this.get('currentAccount.dashboardLanguages') || [];
      var dashboardLanguagesTags = dashboardLanguages.map(function (dashboardLanguage) {
        return dashboardLanguage.get('tag');
      });
      var surveyLanguages = this.get('surveyDraft.translations._languages') || [];

      var filtered = dashboardLanguages.filter(function (dashboardLanguage) {
        var tag = dashboardLanguage.get('tag');

        return !surveyLanguages.includes(tag) && dashboardLanguagesTags.includes(tag);
      });

      return filtered.map(function (language) {
        return { id: language.get('id'), value: language.get('tag') };
      });
    }),

    htmlMeta: computed('languagesToDisplay', 'surveyDraft.html_meta', function () {
      return this.get('surveyDraft.html_meta');
    }),

    surveySteps: computed('refreshPromise.isPending', function () {
      return this.get('surveyDraft.survey_content.survey_steps_attributes');
    }),

    surveyMainLanguage: computed('surveyDraft.main_language', function () {
      return this.get('surveyDraft.main_language');
    }),

    surveyTranslations: computed('refreshPromise.isPending', 'surveyDraft.translations', function () {
      return this.get('surveyDraft.translations');
    }),

    langs: computed('surveyDraft', function () {
      var mainLanguageTag = this.get('surveyDraft.main_language.tag');
      var translations = this.get('surveyDraft.translations') || {};
      var languages = translations['_languages'] || [];
      var availableLanguages = this.get('surveyDraft.available_languages') || [];
      return availableLanguages.reduce(function (a, v) {
        if (!languages.includes(v.tag) && v.tag != mainLanguageTag) {
          a.push({ value: v.tag, id: v.id });
        }return a;
      }, []);
    }),

    defaultLanguage: computed('surveyDraft', function () {
      var mainLanguageTag = this.get('surveyDraft.main_language.tag');
      var mainLanguageId = this.get('surveyDraft.main_language.id');
      return mainLanguageTag != undefined ? { value: mainLanguageTag, id: mainLanguageId } : undefined;
    }),

    _exportTranslations: function _exportTranslations() {
      var translations = this.get('surveyDraft.translations') || {};
      var exportTranslations = Object.keys(translations).filter(function (key) {
        return key != '_languages';
      }).reduce(function (res, key) {
        return res[key] = translations[key], res;
      }, {});
      var link = document.createElement("a");
      var file = new Blob([JSON.stringify(exportTranslations)], { type: 'application/json' });
      link.href = URL.createObjectURL(file);
      link.download = "translations.json";
      link.click();
      URL.revokeObjectURL(link.href);
    },

    _importTranslations: function _importTranslations(json) {
      var translations = this.get('surveyDraft.translations') || {};
      this.set('surveyDraft.translations', _extends({}, translations, JSON.parse(json)));
      this.sendAction('saveRevision');
    },

    actions: {
      updateSurveyTranslations: function updateSurveyTranslations(translations) {
        var languages = this.get("surveyDraft.translations._languages");
        translations._languages = languages;
        this.set('surveyDraft.translations', translations);
        this.notifyPropertyChange('surveyDraft.translations');
        this.set('unsavedChanges', true);
      },

      updateSurveyHtmlMeta: function updateSurveyHtmlMeta(htmlMeta) {
        this.set('surveyDraft.html_meta', htmlMeta);
        this.notifyPropertyChange('surveyDraft.html_meta');
      },

      toggleActionDropdown: function toggleActionDropdown() {
        this.toggleProperty('actionDropdownIsOpen');
      },

      processAction: function processAction(action) {
        this.toggleProperty('actionDropdownIsOpen');
        if (action.key == 'original_language') {
          this.toggleProperty('editMainLanguageAlertIsOpen');
        } else if (action.key == 'export') {
          this._exportTranslations();
        } else if (action.key == 'import') {
          this.toggleProperty('importTranslationsAlertIsOpen');
        }
      },

      editMainLanguage: function editMainLanguage() {
        var _this = this;

        var selectedDefaultLanguage = this.get('selectedDefaultLanguage');
        if (selectedDefaultLanguage == undefined) {
          return;
        }

        var translations = this.get('surveyDraft.translations');
        var mainLanguageTag = this.get("surveyDraft.main_language.tag");
        if (translations['_languages'] == undefined) {
          translations['_languages'] = [];
        }
        var mainLanguageIdx = translations['_languages'].indexOf(mainLanguageTag.toUpperCase());
        if (mainLanguageIdx > -1) {
          translations['_languages'].splice(mainLanguageIdx, 1);
          translations['_languages'].shift(selectedDefaultLanguage.value);
        }
        translations['_languages'] = [].concat(_toConsumableArray(new Set(translations['_languages']))); // only unique values after push
        this.set('surveyDraft.translations', translations);

        this.store.findRecord('language', selectedDefaultLanguage.id).then(function (language) {
          _this.set('surveyDraft.main_language', language);
          _this.set('surveyDraft.confirm_main_language', true);
          _this.sendAction('saveRevision');
          _this.sendAction('refreshLanguagesToDisplay');
        });
        this.toggleProperty('editMainLanguageAlertIsOpen');
      },

      closeEditMainLanguageAlertIsOpen: function closeEditMainLanguageAlertIsOpen() {
        this.set('editMainLanguageAlertIsOpen', false);
        this.set('selectedDefaultLanguage', null);
      },

      selectDefaultLanguage: function selectDefaultLanguage(lang) {
        this.set('selectedDefaultLanguage', lang);
      },

      importTranslations: function importTranslations() {
        var jsonFileBase64 = this.get('translationsFile');
        if (jsonFileBase64.length > 0) {
          var json = atob(jsonFileBase64.substring('data:application/json;base64,'.length));
          this._importTranslations(json);
        }
        this.toggleProperty('importTranslationsAlertIsOpen');
        this.set('translationsFile', '');
      },

      loadTranslations: function loadTranslations(jsonFile) {
        this.set('translationsFile', jsonFile);
      },

      closeImportTranslationsAlertIsOpen: function closeImportTranslationsAlertIsOpen() {
        this.toggleProperty('importTranslationsAlertIsOpen');
        this.set('translationsFile', '');
      },

      cancelTranslations: function cancelTranslations() {
        var savedTranslations = copy(this.get('savedTranslations'), true);
        var savedMeta = copy(this.get('savedHtmlMeta'), true);
        this.send('updateSurveyTranslations', savedTranslations);
        this.send('updateSurveyHtmlMeta', savedMeta);
        this.set('unsavedChanges', false);
      },

      saveTranslations: function saveTranslations() {
        this.set('savedTranslations', copy(this.get('surveyDraft.translations'), true));
        this.set('savedHtmlMeta', copy(this.get('surveyDraft.html_meta'), true));
        this.sendAction('saveRevision');
        this.set('unsavedChanges', false);
      }
    }
  });
});