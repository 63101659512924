define('due-dashboard/router', ['exports', 'ember', 'due-dashboard/config/environment', 'due-dashboard/mixins/google-pageview'], function (exports, _ember, _dueDashboardConfigEnvironment, _dueDashboardMixinsGooglePageview) {

  var Router = _ember['default'].Router.extend(_dueDashboardMixinsGooglePageview['default'], {
    location: _dueDashboardConfigEnvironment['default'].locationType
  });

  Router.map(function () {
    this.route('sign-in');
    this.route('sso-login');
    this.route('forgotten-password');
    this.route('password-reset', { path: '/password-reset/:token' });
    this.route('account-activation', { path: '/account/activation/:token' });
    this.route('account', { resetNamespace: true, path: '/account' }, function () {
      this.route('validation');
    });
    this.route('digest-export', { path: '/digest-export' }, function () {
      this.route('new');
      this.route('show', { path: '/:digest_export_id' });
    });
    this.route('file-export', { path: '/file-export/:exportId' });

    this.route('settings', { resetNamespace: true }, function () {
      this.route('account');
      this.route('billing');
      this.route('company');
      this.route('segments');
      this.route('tags', function () {
        this.route('translations');
      });
      this.route('users', function () {
        this.route('new');
        this.route('edit', { path: '/edit/:account_id' });
        this.route('duplicate', { path: '/duplicate/:account_id' });
      });
      this.route('web-snippets', function () {
        this.route('edit', { path: '/edit/:web_snippet_id' });
      });
      this.route('subscription');
      this.route('api');
      this.route('security');
      this.route('digests', function () {
        this.route('edit', { path: '/edit/:digest_id' });
        this.route('translations', { path: '/translations/:digest_id' });
      });
      this.route('export');
      this.route('integrations');
      this.route('settings.integrations', { resetNamespace: true, path: '/integrations' }, function () {
        var _this = this;

        // auto generated
        // INTEGRATIONS_BEGIN
        ['desk', 'intercom', 'magento', 'prestashop', 'salesforce', 'slack', 'shopify', 'stripe', 'woocommerce', 'zapier', 'zendesk', 'google-my-business', 'microsoft-dynamics', 'hubspot', 'facebook', 'aircall', 'trustpilot'].forEach(function (it) {
          // INTEGRATIONS_END
          _this.route(it, function () {
            this.route('configure');
            this.route('manage');
          });
        });
      });
      this.route('groups', function () {
        this.route('new');
        this.route('edit', { path: '/edit/:account_custom_profile_right_id' });
      });
      this.route('keyword-adjustments');
      this.route('management-question-category', function () {
        this.route('edit', { path: '/edit/:category_id' });
      });
    });

    this.route('companies', { resetNamespace: true, path: '/companies' }, function () {
      this.route('index');
      this.route('overview');
      this.route('report', { path: 'report/:report_id' });
      this.route('companies.libraries', { resetNamespace: true, path: '/libraries' }, function () {
        this.route('companies.libraries.rating-scales', { resetNamespace: true, path: '/rating-scales' }, function () {
          this.route('new');
          this.route('edit', { path: '/edit/:rating_scale_id' });
        });
        this.route('companies.libraries.fonts', { resetNamespace: true, path: '/fonts' }, function () {
          this.route('new');
          this.route('edit');
        });
        this.route('companies.libraries.surveys', { resetNamespace: true, path: '/surveys' }, function () {
          this.route('edit', { path: '/edit/:survey_id' });
        });
      });
      this.route('companies.graphs', { resetNamespace: true, path: '/graphs' }, function () {
        this.route('companies.graphs.edit', { resetNamespace: true, path: '/edit/:graph_id' }, function () {});
      });
      this.route('chats');
      this.route('verbatim');

      this.route('analysis', function () {
        this.route('without-tags', function () {
          this.route('comment', { path: '/:params' }); // :params = without-comment || with-comment
        });
        this.route('with-tags');
        this.route('tags', { path: 'tags/:tag_id' });
      });

      this.route('questions');
      this.route('exports', function () {
        this.route('history');
      });
      this.route('send-surveys');
      this.route('templates', { path: '/templates' }, function () {
        this.route('edit', { path: '/edit/:template_id' });
      });
      this.route('companies.custom-stats', { resetNamespace: true, path: '/custom-stats' }, function () {
        this.route('show', { path: '/:custom_stat_id' });
      });

      this.route('companies.scenarios', { resetNamespace: true, path: '/scenarios' }, function () {
        this.route('companies.scenarios.edit', { resetNamespace: true, path: '/edit/:scenario_id' }, function () {});
        this.route('new');
      });

      this.route('companies.surveys', { resetNamespace: true, path: '/surveys' }, function () {
        this.route('companies.surveys.edit', { resetNamespace: true, path: '/edit/:survey_id' }, function () {
          this.route('main');
          this.route('layout');
          this.route('summary');
          this.route('publishing');

          // QR code
          this.route('mailing-qrcode');

          this.route('companies.surveys.edit.bulk-dispatch', { resetNamespace: true, path: '/bulk-dispatches' }, function () {
            this.route('template', { path: '/:bulk_dispatch_id/template' });
            this.route('send-survey', { path: '/:bulk_dispatch_id/template/:template_id/send-survey' });

            // this.route('recipients');
          });

          // Mailing externe
          this.route('mailing-external');
        });
      });
    });

    this.route('callbacks', { resetNameSpace: true, path: '/callbacks' }, function () {
      this.route('new-survey-with-type', { path: 'new-survey-with-type/:activity_sector_id' });
    });
  });

  exports['default'] = Router;
});