define('due-dashboard/components/v2/survey-editor-tooltip', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({

    classNames: 'diduenjoy-info-circle',
    attributeBindings: ['data-content'],

    didInsertElement: function didInsertElement() {
      var message = this.get('message') || "";
      $(this.$()).tooltipster({
        theme: ['tooltipster-survey-editor'],
        content: message.toString(),
        contentAsHTML: true
      });
    },

    languageUpdate: _ember['default'].observer('message', function () {
      $(this.$()).tooltipster('content', this.get('message').toString());
      $(this.$()).tooltipster('reposition');
    })

  });
});