define('due-dashboard/components/reports/-summary', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var not = _ember['default'].computed.not;
  exports['default'] = Component.extend({
    tagName: 'div',
    classNames: ['summary-container'],
    classNameBindings: ['isHidden:hidden'],
    feedbackModalDisplay: false,

    isHidden: not('displaySummary'),
    summaryContent: computed.alias('summary.summary_content_updated'),
    summaryText: computed.alias('summaryContent.summary'),
    cancelReason: computed.alias('summary.cancel_reason'),
    clientFeeling: computed.alias('summary.client_feeling'),
    clientComment: computed.alias('summary.client_comment'),

    tagSplitedName: computed('summary.tag_name', function () {
      var name = this.get('summary.tag_name');

      if (name) {
        var position = name.lastIndexOf(' > ') + 3;
        return position > 3 ? {
          root: name.substring(0, position),
          leaf: name.substring(position)
        } : {
          root: '',
          leaf: name
        };
      }
    }),

    thumbsUpDisabled: computed('clientFeeling', function () {
      return this.get('clientFeeling') === 'up';
    }),

    thumbsDownDisabled: computed('clientFeeling', function () {
      return this.get('clientFeeling') === 'down';
    }),

    emptyMessge: computed('cancelReason', function () {
      var cancel_reason = this.get('cancelReason'),
          i18n = this.get('i18n'),
          reasons = ['too_large', 'too_low'],
          values = {};
      reasons.forEach(function (e) {
        values[e] = {
          title: i18n.t('components.reports.summary.messages.' + e + '.title'),
          description: i18n.t('components.reports.summary.messages.' + e + '.description')
        };
      });
      return values[cancel_reason];
    }),

    clientFeedback: computed('summaryContent', function () {
      if (this.get('summaryContent.client_feedback')) {
        return this.get('summaryContent.client_feedback');
      } else if (this.get('summaryContent.customer_feedback')) {
        return this.get('summaryContent.customer_feedback');
      }
    }),

    error: computed('SummaryError', function () {
      var error = this.get('SummaryError');
      if (error) return this._handleError(error);
      return false;
    }),

    _handleError: function _handleError(error) {
      var errorText = undefined;
      if (error.errors) errorText = error.errors.map(function (error) {
        return '' + error.detail;
      }).join('<br>');else errorText = this.get('i18n').t('errors.' + error);
      return errorText;
    },

    _toggleFeedbackModalDisplay: function _toggleFeedbackModalDisplay() {
      this.toggleProperty('feedbackModalDisplay');
    },

    actions: {
      thumbsUpAction: function thumbsUpAction() {
        this.set('clientFeeling', 'up');
        this.set('clientComment', null);
        this.get('summary').save();
      },

      thumbsDownAction: function thumbsDownAction() {
        this._toggleFeedbackModalDisplay();
      },

      copyPaste: function copyPaste() {
        if (this.get('emptyMessge')) {
          return this.get('emptyMessge');
        }
        var summary = this.get('summary.tag_name') + "\n\n" + this.get('summaryText') + "\n\n" + this.get('i18n').t('components.reports.summary.content.customers_title') + "\n\n";

        this.get('clientFeedback').forEach(function (content) {
          summary += "\t" + (content.type == "positive" ? "[+] " : "[-] ") + content.title + "\n" + "\t" + content.description + "\n";

          content.list.forEach(function (verbatim) {
            var comment = verbatim.comment;
            if (comment != null && comment.length > 0) {
              // collapse white space
              comment = comment.replace(/\s+/g, " ");
              // remove \n
              comment = comment.replace(/[\r\n]+/g, "");
            }

            summary += "\t\t(" + verbatim.score + ") " + comment + "\n";
          });
          summary += "\n";
        });

        summary += "(" + this.get('i18n').t('components.reports.summary.content.legal_legend') + ")\n";

        navigator.clipboard.writeText(summary);
      },

      saveNegativeFeedback: function saveNegativeFeedback() {
        var _this = this;

        this.set('clientFeeling', 'down');
        var updatePromise = this.get('summary').save();
        this.set('updatePromise', updatePromise);
        updatePromise.then(function (_) {
          return _this._toggleFeedbackModalDisplay();
        });
      },

      closeThumbDownModal: function closeThumbDownModal() {
        this.set('clientComment', null);
        this._toggleFeedbackModalDisplay();
      }
    }
  });
});