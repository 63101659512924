define('due-dashboard/objects/reports/data-converter/table-data-converter-factory', ['exports', 'ember', 'due-dashboard/objects/reports/data-converter/tag-table-converter', 'due-dashboard/objects/reports/data-converter/segment-table-converter', 'due-dashboard/objects/reports/data-converter/question-table-converter', 'due-dashboard/objects/reports/data-converter/tag-breakdown-table-converter', 'due-dashboard/objects/reports/data-converter/segment-breakdown-table-converter'], function (exports, _ember, _dueDashboardObjectsReportsDataConverterTagTableConverter, _dueDashboardObjectsReportsDataConverterSegmentTableConverter, _dueDashboardObjectsReportsDataConverterQuestionTableConverter, _dueDashboardObjectsReportsDataConverterTagBreakdownTableConverter, _dueDashboardObjectsReportsDataConverterSegmentBreakdownTableConverter) {
  exports['default'] = _ember['default'].Object.extend({

    converter: null,
    converters: {
      tags: _dueDashboardObjectsReportsDataConverterTagTableConverter['default'],
      segments: _dueDashboardObjectsReportsDataConverterSegmentTableConverter['default'],
      questions: _dueDashboardObjectsReportsDataConverterQuestionTableConverter['default'],
      'tag-breakdown': _dueDashboardObjectsReportsDataConverterTagBreakdownTableConverter['default'],
      'segment-breakdown': _dueDashboardObjectsReportsDataConverterSegmentBreakdownTableConverter['default'],
      'group': _dueDashboardObjectsReportsDataConverterSegmentTableConverter['default']
    },

    init: function init() {
      var type = this.get('type');
      if (type in this.converters) {
        this.converter = this.converters[type].create();
      }
    },

    getConverter: function getConverter() {
      return this.converter;
    }
  });
});