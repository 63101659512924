define('due-dashboard/components/v2/libraries/surveys/navbar/elements/-trustpilot-redirect-btn', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var set = _ember['default'].set;
  exports['default'] = Component.extend({
    classNames: ['surveys-library-navbar-content-element-trustpilot-btn'],
    searchSegmentValue: '',
    reviewTypeList: ['Service', 'Product', 'Location'],
    cachedValues: {},

    didUpdateAttrs: function didUpdateAttrs() {
      this._super.apply(this, arguments);
      this.updateAttrs();
    },

    updateAttrs: function updateAttrs() {
      var _this = this;

      var content = this.get('content');
      Object.keys(content).map(function (key) {
        return _this.cachedValues[key] = content[key];
      });
    },

    segment_for_name_segmentList: computed('segment_for_name_filteredSegments', 'group.segmentsList', function () {
      return this.get('searchSegmentValue').length > 0 ? this.get('segment_for_name_filteredSegments') : this.get('group.segmentsList');
    }),

    segment_for_reference_segmentList: computed('segment_for_reference_filteredSegments', 'group.segmentsList', function () {
      return this.get('searchSegmentValue').length > 0 ? this.get('segment_for_reference_filteredSegments') : this.get('group.segmentsList');
    }),

    segment_for_location_segmentList: computed('segment_for_location_filteredSegments', 'group.segmentsList', function () {
      return this.get('searchSegmentValue').length > 0 ? this.get('segment_for_location_filteredSegments') : this.get('group.segmentsList');
    }),

    segment_for_product_segmentList: computed('segment_for_product_filteredSegments', 'group.segmentsList', function () {
      return this.get('searchSegmentValue').length > 0 ? this.get('segment_for_product_filteredSegments') : this.get('group.segmentsList');
    }),

    _filterSegmentList: function _filterSegmentList(key) {
      var value = this.get('searchSegmentValue').toLowerCase();
      var segmentsList = this.get('group.segmentsList');
      if (segmentsList) {
        var filteredList = segmentsList.filter(function (segment) {
          return segment.text.toLowerCase().includes(value);
        });
        this.set(key + '_filteredSegments', filteredList);
      }
    },

    actions: {
      onChangeContentField: function onChangeContentField() {
        var saveCallback = this.get('contentUpdateCallback');
        var content = this.get('content');

        if (saveCallback) {
          saveCallback(content);
        }
      },

      searchSegment: function searchSegment(key, searchValue) {
        this.set('searchSegmentValue', searchValue || '');
        this._filterSegmentList(key);
      },

      updateContentField: function updateContentField(key, value) {
        var focus = this.get('focus');
        if (!focus && value !== -1) this.set('focus', true);

        var valueChanged = !this.cachedValues[key] || this.cachedValues[key] && !(this.cachedValues[key] === value);
        if (value !== -1 && valueChanged) {
          this.cachedValues[key] = value;
          this.set('content.' + key, value);
          if (this.get('content.segment_for_name') && this.get('content.segment_for_reference') && this.get('content.review_type')) {
            var onChangeContentField = false;
            switch (this.get('content.review_type')) {
              case 'Service':
                onChangeContentField = true;
                break;
              case 'Location':
                onChangeContentField = this.get('content.segment_for_location');
                break;
              case 'Product':
                onChangeContentField = this.get('content.segment_for_product') && this.get('content.segment_for_product_separator') && this.get('content.segment_for_product_separator').length > 0;
                break;
            }
            if (onChangeContentField) this.send('onChangeContentField');else {
              var ignoreChangesCallback = this.get('ignoreChangesCallback');
              if (ignoreChangesCallback) ignoreChangesCallback();
            }
          }
        }
        if (value === -1 && this.get('focus')) this.set('focus', false);
      }
    }
  });
});