define('due-dashboard/components/v2/-tag-badge', ['exports', 'ember', 'due-dashboard/helpers/get-rgba-from-hex'], function (exports, _ember, _dueDashboardHelpersGetRgbaFromHex) {
  var computed = _ember['default'].computed;
  var Component = _ember['default'].Component;
  exports['default'] = Component.extend({
    classNames: ['tag-badge-v2'],
    attributeBindings: ['style'],
    classNameBindings: ['secondaryState:secondary-state'],
    colorPanelLeft: 0,
    deleting: false,
    currentLanguage: computed.alias('currentAccount.content.language.tag'),
    feelingColors: { positive: '#00B548', neutral: '#FAB005', negative: '#FA5252', allfeedback: '#71899C', nosentiment: '#71899C' },
    feelingIcons: { positive: 'thumb_up_outlined', neutral: 'thumb_left_outlined', negative: 'thumb_down_outlined', allfeedback: 'message_add_outlined', nosentiment: 'label_outlined' },

    rights: computed('from_due', 'canClose', 'canAutoClose', function () {
      return {
        canBeClose: !this.get('readOnly') && (this.get('tag.due_exclusive') && this.get('canAutoClose') || !this.get('tag.due_exclusive') && this.get('canClose'))
      };
    }),

    feelingIcon: computed('feeling', 'from_due', function () {
      return this.get('from_due') ? this.get('feelingIcons')[this.get('feeling')] : null;
    }),

    style: computed('tag.color', 'feeling', function () {
      var color = this.get('feelingColors')[this.get('feeling')] || this.get('tag.color') || this.get('color');
      if (color) {
        return 'background-color: ' + _dueDashboardHelpersGetRgbaFromHex['default'].compute(color, 0.05) + ';color: ' + color + ';border: 1px solid ' + color + ';';
      }
    }),

    showColorPanel: computed('tag.tempColor', function () {
      return this.get('tag.tempColor');
    }),

    suffixName: computed('tag', 'tag.translations', 'currentLanguage', 'tag.name', function () {
      var name = '';

      if (this.get('currentLanguage') && this.get('tag.translations')) {
        name = this.get('tag.translations')[this.get('currentLanguage').toLowerCase()] ? this.get('tag.translations')[this.get('currentLanguage').toLowerCase()]['text'] : this.get('tag.name');
      } else {
        name = this.get('tag.name');
      }
      var nameArray = name.split(' > ');
      nameArray.splice(-1);
      return nameArray[nameArray.length - 1];
    }),

    name: computed('tag', 'tag.translations', 'currentLanguage', 'tag.name', function () {
      var name = '';

      if (this.get('currentLanguage') && this.get('tag.translations')) {
        name = this.get('tag.translations')[this.get('currentLanguage').toLowerCase()] ? this.get('tag.translations')[this.get('currentLanguage').toLowerCase()]['text'] : this.get('tag.name');
      } else {
        name = this.get('tag.name');
      }

      var nameArray = name.split(' > ');
      return nameArray[nameArray.length - 1];
    }),

    autoTagsRights: computed.alias('currentAccount.canUseAutoTags'),

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      var pageWidth = _ember['default'].$('body > .ember-view').width(),
          spaceLeft = pageWidth - this.element.getBoundingClientRect().x;
      if (spaceLeft < 200) {
        this.set('colorPanelLeft', 180 - this.element.getBoundingClientRect().width);
      } else {
        this.set('colorPanelLeft', 0);
      }
    },

    actions: {
      removeTag: function removeTag(e) {
        e.stopPropagation();
        this.set('deleting', true);
        this.get('removeTagAction')(this.get('tag'), this.get('feeling'));
      },

      selectColorAction: function selectColorAction(color) {
        this.store.findRecord('tag', this.get('tag.id'), { reload: true }).then(function (tag) {
          tag.color = color;
          tag.save();
        });
        this.set('tag.tempColor', false);
        this.set('tag.color', color);
      }
    }
  });
});