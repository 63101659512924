define('due-dashboard/helpers/default-survey-v2-template', ['exports', 'ember'], function (exports, _ember) {
  exports.defaultSurveyV2Template = defaultSurveyV2Template;

  function defaultSurveyV2Template(defaultRatingScaleId, translations, tag, surveyType) {
    var template = defaultTemplate[surveyType];

    template.forEach(function (step, stepIdx) {
      step.uuid = crypto.randomUUID();
      step.conditions.forEach(function (condition) {
        condition.uuid = crypto.randomUUID();
      });
      step.survey_elements_attributes.forEach(function (element, elementIdx) {
        element.uuid = crypto.randomUUID();

        if (element.kind === 'rating') element.content.rating_scale_id = defaultRatingScaleId;

        element.conditions.forEach(function (condition) {
          condition.uuid = crypto.randomUUID();
        });

        var elementTranslations = translations[tag.toLowerCase()].elements[stepIdx][elementIdx];
        if (elementTranslations) {
          var translationKeys = Object.keys(elementTranslations);
          translationKeys.forEach(function (translationElementKey) {
            element.content[translationElementKey] = elementTranslations[translationElementKey];
          });
        }
      });
    });

    return { survey_steps_attributes: template };
  }

  exports['default'] = _ember['default'].Helper.helper(defaultSurveyV2Template);

  var defaultTemplate = {
    nps: [{
      "name": "Recommandation",
      "conditions": [],
      "kind": "content",
      "uuid": "",
      "survey_elements_attributes": [{
        "uuid": "",
        "name": "Text",
        "kind": "text",
        "conditions": [],
        "content": {
          "text": "It has been a pleasure to welcome you!"
        },
        "position": 0
      }, {
        "uuid": "",
        "name": "Rating - NPS Question",
        "kind": "rating",
        "conditions": [],
        "content": {
          "na_label": "N/A",
          "label": "Would you recommend {company_name} to your friends and family?",
          "style": {
            "rating_legend": "rating-legend-inline",
            "rating_legend_top": "rating-hidden"
          },
          "legend": {
            "low_score": "0 - Absolutely not",
            "high_score": "10 - Yes, absolutely"
          },
          "mandatory": true,
          "main_rating": true,
          "rating_scale_id": ""
        },
        "position": 1
      }, {
        "uuid": "",
        "name": "Long text input",
        "kind": "long_text_input",
        "conditions": [],
        "content": {
          "label": "Tell us more about your experience",
          "placeholder": "Write here",
          "main_comment": true
        },
        "position": 2
      }, {
        "uuid": "",
        "name": "Navigation button",
        "kind": "prev_next_btns",
        "conditions": [],
        "content": {
          "next_button_text": "NEXT"
        },
        "position": 3
      }, {
        "uuid": "",
        "name": "Checkpoint",
        "kind": "soft_complete",
        "conditions": [{
          "data": {
            "answer_in": ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10"],
            "answer_step": 0,
            "answer_element": 1
          },
          "kind": "answer_in"
        }],
        "content": {},
        "position": 4
      }],
      "position": 0
    }, {
      "name": "Hard complete",
      "conditions": [],
      "kind": "hard_complete",
      "uuid": "",
      "survey_elements_attributes": [],
      "position": 1
    }, {
      "name": "Thank you page",
      "conditions": [],
      "kind": "content",
      "uuid": "",
      "survey_elements_attributes": [{
        "uuid": "",
        "name": "Thank You Page",
        "kind": "text",
        "conditions": [],
        "content": {
          "text": "Thank you for your feedback which is much appreciated!\n\nWe hope to see you again soon!"
        },
        "position": 0
      }, {
        "uuid": "",
        "name": "Redirection link",
        "kind": "redirect_btn",
        "conditions": [],
        "content": {
          "type": "primary",
          "text": "Discover our latest innovations!",
          "url": "https://www.diduenjoy.com/fr"
        },
        "position": 1
      }],
      "position": 2
    }],
    csat: [{
      "name": "Recommandation",
      "conditions": [],
      "kind": "content",
      "uuid": "",
      "survey_elements_attributes": [{
        "kind": "text",
        "name": "Text",
        "uuid": "",
        "content": {
          "text": "It has been a pleasure to welcome you!"
        },
        "position": 0,
        "conditions": []
      }, {
        "kind": "rating",
        "name": "Rating - CSAT Question",
        "uuid": "",
        "content": {
          "na_label": "N/A",
          "label": "How satisfied are you with your overall experience with {company_name}?",
          "style": {
            "rating_legend": "rating-legend-inline",
            "rating_legend_top": "rating-hidden"
          },
          "legend": {
            "low_score": "1 - Very dissatisfied",
            "high_score": "5 - Very satisfied"
          },
          "mandatory": true,
          "main_rating": true,
          "rating_scale_id": ""
        },
        "position": 1,
        "conditions": []
      }, {
        "kind": "long_text_input",
        "name": "Long text input",
        "uuid": "",
        "content": {
          "label": "Tell us more about your experience",
          "placeholder": "Write here",
          "main_comment": true
        },
        "position": 2,
        "conditions": []
      }, {
        "kind": "prev_next_btns",
        "name": "Navigation button",
        "uuid": "",
        "content": {
          "next_button_text": "Next"
        },
        "position": 3,
        "conditions": []
      }, {
        "kind": "soft_complete",
        "name": "Checkpoint",
        "uuid": "",
        "content": {},
        "position": 4,
        "conditions": [{
          "data": {
            "answer_in": ["1", "2", "3", "4", "5"],
            "answer_step": 0,
            "answer_element": 1
          },
          "kind": "answer_in",
          "uuid": "",
          "has_been_cmptd": false
        }]
      }],
      "position": 0
    }, {
      "name": "Hard complete",
      "conditions": [],
      "kind": "hard_complete",
      "uuid": "",
      "survey_elements_attributes": [],
      "position": 1
    }, {
      "name": "Thank you page",
      "conditions": [],
      "kind": "content",
      "uuid": "",
      "survey_elements_attributes": [{
        "uuid": "",
        "name": "Thank You Page",
        "kind": "text",
        "conditions": [],
        "content": {
          "text": "Thank you for your feedback which is much appreciated!\n\nWe hope to see you again soon!"
        },
        "position": 0
      }, {
        "uuid": "",
        "name": "Redirection link",
        "kind": "redirect_btn",
        "conditions": [],
        "content": {
          "type": "primary",
          "text": "Discover our latest innovations!",
          "url": "https://www.diduenjoy.com/fr"
        },
        "position": 1
      }],
      "position": 2
    }],
    custom: [{
      "kind": "content",
      "name": "Recommandation",
      "uuid": "",
      "position": 0,
      "conditions": [],
      "survey_elements_attributes": []
    }, {
      "name": "Hard complete",
      "conditions": [],
      "kind": "hard_complete",
      "uuid": "",
      "survey_elements_attributes": [],
      "position": 1
    }, {
      "kind": "content",
      "name": "Thank you page",
      "uuid": "",
      "position": 2,
      "conditions": [],
      "survey_elements_attributes": []
    }]
  };
});