define('due-dashboard/components/due-input', ['exports', 'ember-data', 'ember'], function (exports, _emberData, _ember) {
  /* globals $ */

  var computed = _ember['default'].computed;
  var Component = _ember['default'].Component;
  var run = _ember['default'].run;
  var guidFor = _ember['default'].guidFor;
  var RSVP = _ember['default'].RSVP;

  /*
    {{due-input}}
      input type text component
      author: Romain Gay
  
    some help:
      - data binding:
        value variable passed as mut: value=(mut model.name)
      - iconable:
        pass a tio-icon-name to display it in the input field: for example: icon=search
  
    full usage:
      {{due-input
        name=[name of input element]
        disabled=[disabled variable]
        debounce=[ms value for input debouncing]
        value=[mut to input value]
        placeholder=[placeholder]
        focused=[focus state, assignable]
        type=[number,]
        promise=[search promise]
        showLoading=[display or not loading spinner]
        onChange=[binded action - called after keyup event]
        prefixLabel=[display label before the input]
        suffixlabel=[display label after the input]
        error=[boolean]
        validate=[boolean]
      }}
  
    todo:
      labels
      // dropdown needed for this:
      autocomplete= [ultimately not needed because will be hooked on onkey event]
      selected= [array containing labels for when multiselect]
  
  */

  exports['default'] = Component.extend({
    classNames: ['due-input'],
    classNameBindings: ['focused', 'error', 'validate', 'disabled', 'blockDisplay', 'customClasses'],
    disabled: false,
    focused: false,
    icon: null,
    name: null,
    prevValue: null,
    value: null,
    style: '',
    selected: [],
    error: false,
    valid: false,
    onChange: null,
    debounce: 300,
    inputElement: null,
    uid: null,
    suffixLabel: null,
    prefixLabel: null,
    computedBorders: '',
    defaultColor: '#1B3448',
    maxFileSize: 3000000,
    uploadFileName: null,
    triggerOnChangeOnFocusOut: false,

    init: function init() {
      this._super.apply(this, arguments);
      if (!this.get('prefixLabel')) {
        this.set('computedBorders', 'input-left-borders ');
      }
      if (!this.get('suffixLabel')) {
        this.set('computedBorders', this.get('computedBorders') + 'input-right-borders');
      }
      if (this.get('type') === 'color') {
        this.set('compType', 'text');
        if (this.get('value').length < 4) {
          this.set('value', this.get('defaultColor'));
        }
      } else {
        this.set('compType', this.get('type'));
      }
      this.set('prevValue', this.get('value'));
    },

    didInsertElement: function didInsertElement() {
      if (this.get('type') === 'textarea') {
        this.set('inputElement', this.$('textarea')[0]);
      } else {
        this.set('inputElement', this.$('input')[0]);
      }
      this.set('uid', guidFor(this));
      if (this.get('focused')) {
        this.get('inputElement').focus();
      }
      if (this.get('pattern')) {
        this.get('inputElement').setAttribute("onpaste", "return false"); // ember input does not expose onpaste
      }
    },

    onKeyUp: function onKeyUp(event) {
      var triggeredKeyCodes = ['Enter'];
      var value = this.get('value');

      if (this.get('ignoreEmptyValue') && value === '') return;
      var eventTrigger = event && triggeredKeyCodes.includes(event.originalEvent.code);

      if (this.get('type') == 'number') {
        value = value != undefined ? value.replace(/[Ee\+]/gi, "") : "0";
        value = String(Number(value.length > 0 ? value : '0'));
        this.set('value', value);
      }

      if ((this.get('onChange') != null && value != this.get('prevValue') || eventTrigger) && !this.isDestroyed) {
        var onPressEnter = this.get('onPressEnter');
        var onChange = this.get('onChange');

        this.set('prevValue', value);
        if (onChange) onChange(value, event);
        if (onPressEnter && event.originalEvent.code === 'Enter') onPressEnter(value, event);
      }
    },

    loadingPromise: computed('promise', function () {
      var promise = [this.get('promise')];
      return _emberData['default'].PromiseArray.create({
        promise: new RSVP.Promise(function (resolve) {
          RSVP.allSettled(promise).then(function () {
            setTimeout(function () {
              resolve();
            }, 500);
          });
        })
      });
    }),

    displayDeleteIcon: computed('deleteIcon', 'value', function () {
      return this.get('deleteIcon') && !!this.get('value');
    }),

    _handleError: function _handleError(error) {
      var onError = this.get('onError');
      if (onError) {
        onError(error.type, error.msg);
      } else {
        this.set('error', error.type);
        this.set('errorMsgKey', error.msg);
      }
    },

    actions: {

      keyUp: function keyUp(_, event) {
        run.debounce(this, this.onKeyUp, event, this.get('debounce'));
      },

      keyDown: function keyDown(_, event) {
        var pattern = this.get('pattern');
        if (pattern) {
          event.originalEvent.returnValue = new RegExp(pattern).test(event.originalEvent.key);
        }
      },

      focus: function focus(focused) {
        if (this.get('disabled')) {
          return;
        }
        this.set('focused', focused);
        if (focused && this.get('inputElement')) {
          var onFocusIn = this.get('onFocusIn');
          if (onFocusIn) onFocusIn();
          this.get('inputElement').focus();
        } else if (this.get('triggerOnChangeOnFocusOut')) {
          this.get('onChange')(this.get('value'));
          var onFocusOut = this.get('onFocusOut');
          if (onFocusOut) onFocusOut();
        }
      },
      // handle onclick

      cleanValue: function cleanValue() {
        if (this.get('onChange') !== null) {
          this.set('prevValue', this.get('value'));
          this.set('value', '');
          this.get('onChange')('');
        }
      },

      imageChange: function imageChange() {
        var _this = this;
        var input = this.$('input')[0];
        var file = $(input).prop('files')[0];
        if (file) {
          var maxSize = this.get('maxFileSize');
          try {
            if (maxSize && file.size > maxSize) {
              throw {
                type: 'too_large',
                msg: 'components.errors.due_input.file_too_large'
              };
            }
            if (this.get('typeCheck') && !file.type.match(/^image\//)) {
              throw {
                type: 'invalid_type',
                msg: 'components.errors.due_input.extension_not_supported'
              };
            }
          } catch (error) {
            input.value = '';
            this._handleError(error);
            return;
          }
          var reader = new FileReader();
          reader.onload = function (e) {
            _this.set('src', e.target.result);
            _this.set('error', false);
            _ember['default'].run.next(function () {
              _this.sendAction('onChange', e.target.result);
              _this.set('uploadFileName', file.name);
            });
          };
          reader.readAsDataURL(file);
        }
      },

      selectSuffixOption: function selectSuffixOption(selectedSuffixOption) {
        var func = this.get('onSelectSuffixOption');
        if (func) {
          func(selectedSuffixOption);
        }
      },

      openFileSystem: function openFileSystem() {
        var inputId = this.get('inputId');
        var input = document.getElementById(inputId);

        input.click();
      }
    }
  });
});