define('due-dashboard/components/due-table/due-table-empty', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({

    emptyHeader: {
      leftSideHeader: [{ type: 'index', component: 'text', width: 20 }, { type: 'label-with-sub', component: 'text', width: 50 }],
      rightSideHeader: [{ type: 'label-with-sub', component: 'text', width: 80 }, { type: 'label-with-sub', component: 'text', width: 80 }, { type: 'label-with-sub', component: 'text', width: 80 }]
    },

    emptyPage: {
      leftSideContent: [[{ type: 'index', component: 'text', width: 20 }, { type: 'label-with-sub', component: 'text', width: 50 }], [{ type: 'index', component: 'text', width: 20 }, { type: 'label-with-sub', component: 'text', width: 50 }]],
      rightSideContent: [[{ type: 'index', component: 'text', width: 80 }, { type: 'label-with-sub', component: 'nps-line', width: 150 }, { type: 'label-with-sub', component: 'text', width: 1600 }], [{ type: 'index', component: 'text', width: 80 }, { type: 'label-with-sub', component: 'nps-line', width: 150 }, { type: 'label-with-sub', component: 'text', width: 1600 }]]
    }
  });
});