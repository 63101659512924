define('due-dashboard/components/v2/-rating-scale-dropdown', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  exports['default'] = Component.extend({
    classNames: ['rating-scale-dropdown'],
    isOpen: false,

    init: function init() {
      this._super.apply(this, arguments);
    },

    dropdownContent: computed('ratingScales', function () {
      var _this = this;

      var res = [];
      var rss = this.get('ratingScales');
      var categories = ['nps', 'csat', 'custom'];

      categories.forEach(function (category) {
        if (rss[category].length > 0) {
          res.push({
            type: 'title',
            text: _this.get('i18n').t('words.' + category)
          });
          rss[category].sort(function (a, b) {
            return b.default_custom_rating_scale - a.default_custom_rating_scale;
          }).forEach(function (item) {
            res.push(item);
          });
        }
      });
      return res;
    }),

    actions: {
      onSelectRatingScale: function onSelectRatingScale(ratingScale) {
        var callback = this.get('onSelectRatingScale');

        if (callback) {
          callback(ratingScale);
          this.send('closeDropdown');
        }
      },

      toggleDropdown: function toggleDropdown() {
        this.toggleProperty('isOpen');
      },

      closeDropdown: function closeDropdown() {
        this.set('isOpen', false);
      }
    }
  });
});
/**
 * @author Antoine Yvroud <antoine@diduenjoy.com>
 */