define('due-dashboard/objects/reports/data-converter/segment-breakdown-table-converter', ['exports', 'due-dashboard/objects/reports/data-converter/segment-table-converter'], function (exports, _dueDashboardObjectsReportsDataConverterSegmentTableConverter) {
  var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

  exports['default'] = _dueDashboardObjectsReportsDataConverterSegmentTableConverter['default'].extend({

    FILTERED_VALUES: {
      '_not_specified_': 'words.missing_data',
      '_empty_value_': 'words.empty'
    },

    getRows: function getRows(data, tableParams, valuesHierarchy, remainingLinesNumber, currentLanguage, i18n, loadingState) {
      var rows = [];
      var content = this._getSegmentsContent(data, tableParams, currentLanguage, i18n);
      content.forEach(function (line) {
        line._metadata.valuesHierarchy = valuesHierarchy.concat([line.mainValue]);
        rows.push(line);
      });
      if (remainingLinesNumber > 0 && !loadingState) {
        var loadMoreElement = {
          data: [_extends({}, this.columnTypes.loadMore, { value: remainingLinesNumber })],
          _metadata: { valuesHierarchy: valuesHierarchy, navElement: true }
        };
        rows.push(loadMoreElement);
      }
      return rows;
    },

    _getSegmentsContent: function _getSegmentsContent(content, tableParams, currentLanguage, i18n) {
      var _this = this;

      var serializedSegments = [];
      content.forEach(function (line, lineIdx) {
        var _metadata = {};
        var serializedSegment = [];
        var mainValue = undefined;
        if (tableParams.index) {
          serializedSegment.push(_extends({}, _this.columnTypes.index, { value: lineIdx + (tableParams.page_number - 1) * tableParams.page_size }));
        }
        line.forEach(function (elem, idx) {
          if (idx === 0) {
            var mainColumn = _extends({}, elem, { main_column: true });
            if (elem.value in _this.FILTERED_VALUES) {
              _metadata.filtered = true;
              mainColumn.value = i18n.t(_this.FILTERED_VALUES[elem.value]);
            } else if (elem.type == 'loading') {
              _metadata.navElement = true;
            }
            mainValue = elem.value;
            serializedSegment.push(mainColumn);
          } else {
            if (elem.type == 'breakdown') {
              var breakdown = _this._formatBreakdown(elem, tableParams.properties[idx - 1], tableParams, currentLanguage);
              breakdown.value.title = tableParams.translations[currentLanguage].headers[idx];
              serializedSegment.push(breakdown);
            } else if (elem.type == 'number-trend') {
              serializedSegment.push(_this._formatNumberTrend(elem, tableParams.properties[idx - 1], tableParams, i18n));
            } else if (['positive-impact', 'negative-impact'].includes(elem.type)) {
              serializedSegment.push(_extends({}, elem, { positiveImpactHideValue: tableParams.positiveImpactHideValue, negativeImpactHideValue: tableParams.negativeImpactHideValue }));
            } else {
              serializedSegment.push(_extends({}, elem, { kpi: tableParams.properties[idx - 1].kpi, attrs: tableParams.properties[idx - 1].attrs }));
            }
          }
        });
        serializedSegments.push({ data: serializedSegment, mainValue: mainValue, _metadata: _metadata });
      });
      return serializedSegments;
    },

    addLevel: function addLevel(tableData, newLevel, valuesHierarchy) {
      var _this2 = this;

      if (valuesHierarchy.length == 0) {
        if (tableData.length > 0) {
          tableData.pop();
        }
        newLevel.slice(tableData.length).forEach(function (lvl) {
          return tableData.push(lvl);
        });
        return;
      }
      tableData.forEach(function (line) {
        if (line._metadata.navElement) {
          return;
        }
        if (line._metadata.valuesHierarchy.toString() == valuesHierarchy.toString()) {
          if (line.subLines == undefined) {
            line.subLines = newLevel;
          } else {
            line.subLines.pop();
            newLevel.slice(line.subLines.length).forEach(function (lvl) {
              return line.subLines.push(lvl);
            });
          }
          return;
        }
        if (line.subLines && line.subLines.length > 0) {
          _this2.addLevel(line.subLines, newLevel, valuesHierarchy);
        }
      });
    },

    formatData: function formatData(params) {
      var tableParams = params.tableParams;
      var data = params.data;
      var selectedViewBy = params.selectedViewBy;
      var currentLanguage = params.currentLanguage;
      var valuesHierarchy = params.valuesHierarchy;
      var remainingLinesNumber = params.remainingLinesNumber;
      var i18n = params.i18n;
      var loadingState = params.loadingState;

      return {
        headers: this.getHeaders(tableParams, selectedViewBy, currentLanguage || 'EN'),
        rows: this.getRows(data, tableParams, valuesHierarchy, remainingLinesNumber, currentLanguage || 'EN', i18n, loadingState)
      };
    }
  });
});