define('due-dashboard/components/due-table/navigation-button', ['exports', 'ember', 'due-dashboard/objects/feedback-search/filter-set'], function (exports, _ember, _dueDashboardObjectsFeedbackSearchFilterSet) {
  var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

  var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var getOwner = _ember['default'].getOwner;
  var service = _ember['default'].inject.service;
  exports['default'] = Component.extend({
    currentAccount: service('current-account'),
    _routing: service('-routing'),
    store: service('store'),
    filters: service('filters'),
    globalFilters: service('feedback-search/global'),

    tagName: 'div',
    classNames: ['navigation-button'],
    classNameBindings: ['visibility'],

    translations: computed('config.translations', 'currentAccount.content.language.tag', function () {
      var translations = this.get('config.translations'),
          locale = this.get('currentAccount.content.language.tag').toUpperCase();

      return translations[locale];
    }),

    text: computed('translations', function () {
      var translations = this.get('translations');

      return translations ? translations.button_text : '';
    }),

    _setFilters: function _setFilters(filters) {
      // Legacy support
      var feeling_filter = filters.tag_sentiment || 'allfeedback';
      if (filters.withTags) {
        var tag_sentiments = _.reduce(filters.withTags, function (sentiments, id) {
          return _extends({}, sentiments, _defineProperty({}, id, [feeling_filter]));
        }, {});
        delete filters.withTags;
        filters.tags = { included: 'tagged', tags_sentiments: tag_sentiments };
      }
      var pageFilters = this.get('pageFilters.surveys');
      if (pageFilters) {
        filters.surveys = pageFilters[0] || [];
      }

      var current = this.get('globalFilters.current');
      delete current.tags;
      var updated = current.merge(_dueDashboardObjectsFeedbackSearchFilterSet['default'].createWith(this.container, filters));

      this.get('globalFilters').updateCurrent(updated);
      this.notifyPropertyChange('globalFilters._current.tags.tags_sentiments');
      this.notifyPropertyChange('globalFilters._current.surveys');
    },

    actions: {
      transitionToTarget: function transitionToTarget() {
        var target = this.get('config.target'),
            filters = _extends({}, target.filters.template),
            routing = this.get('_routing');

        var _iteratorNormalCompletion = true;
        var _didIteratorError = false;
        var _iteratorError = undefined;

        try {
          for (var _iterator = target.filters.substitutions[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
            var _step$value = _slicedToArray(_step.value, 2);

            var target_path = _step$value[0];
            var source_path = _step$value[1];

            var source_value = this.get(source_path);

            _.set(filters, target_path, source_value);
          }
        } catch (err) {
          _didIteratorError = true;
          _iteratorError = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion && _iterator['return']) {
              _iterator['return']();
            }
          } finally {
            if (_didIteratorError) {
              throw _iteratorError;
            }
          }
        }

        this._setFilters(filters);
        return routing.transitionTo(target.route_name, target.dynamic_segments);
      }
    }
  });
});