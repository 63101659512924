define('due-dashboard/objects/reports/data-converter/tag-breakdown-table-converter', ['exports', 'due-dashboard/objects/reports/data-converter/table-converter'], function (exports, _dueDashboardObjectsReportsDataConverterTableConverter) {
  var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

  /* global _ */

  exports['default'] = _dueDashboardObjectsReportsDataConverterTableConverter['default'].extend({

    getHeaders: function getHeaders(tableParams, currentLanguage) {
      var headerTxt = tableParams.translations[currentLanguage].headers;
      var headerTooltip = tableParams.translations[currentLanguage].header_tooltip;
      headerTxt = headerTxt.map(function (txt) {
        return txt.length > 70 ? txt.slice(0, 70) + '...' : txt;
      });

      var headers = [{
        type: 'label-with-sub',
        label: headerTxt[0],
        main_column: true
      }];
      headerTxt.slice(1).forEach(function (txt, index) {
        var columnType = tableParams.properties[index].type;
        var header = {
          type: columnType,
          label: txt,
          main_column: false,
          sortable: tableParams.sortable && columnType != 'breakdown'
        };
        if (headerTooltip != null && headerTooltip[txt]) {
          header.tooltip = headerTooltip[txt];
        }
        headers.push(header);
      });
      return headers;
    },

    getRows: function getRows(data, tableParams, currentLanguage, i18n) {
      var content = this._getTagBreakdownContent(data, tableParams, currentLanguage, i18n);
      return this._computeTagBreakdown(content);
    },

    _computeTagBreakdown: function _computeTagBreakdown(data) {
      var _this = this;

      var lowestLevel = _(data).map(function (tag) {
        return tag[0].value.original_name.split(' > ').length;
      }).min();
      var rootTags = data.filter(function (tag) {
        var splitName = tag[0].value.original_name.split(' > ');

        return splitName.length === lowestLevel;
      });

      if (rootTags.length == 0) {
        return [];
      }

      var res = [];
      rootTags.forEach(function (tag) {
        res.push(_this._computeTags(data, tag));
      });
      return res;
    },

    _computeTags: function _computeTags(tags, tag) {
      var _this2 = this;

      var res = { data: tag };

      var tagName = tag[0].value.original_name;
      var subTagRegExp = "^" + tagName.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&') + " > [^>]*$";

      var subTags = tags.filter(function (item) {
        return item[0].value.original_name.match(subTagRegExp);
      });
      if (subTags.length > 0) {
        (function () {
          var subLines = [];
          subTags.forEach(function (subTag) {
            subLines.push(_this2._computeTags(tags, subTag));
          });
          res['subLines'] = subLines;
        })();
      }
      tag[0].tagValue = true;
      return res;
    },

    _formatBreakdown: function _formatBreakdown(elem, properties, tableParams, currentLanguage) {
      var value = _extends({}, elem.value);

      if (value.breakdown) {
        value.distribution = value.breakdown;
      }

      var distribution = value.distribution;
      var computedBreakdown = {};
      var total = 0;

      if (!distribution) {
        value.distribution = [];
        return _extends({}, elem, { value: value });
      }

      if (distribution['_total_multi'] !== undefined) {
        total = distribution['_total_multi'];
        delete distribution['_total_multi'];
      } else {
        Object.values(distribution).forEach(function (sectionTotal) {
          total += sectionTotal;
        });
      }
      value.total = total;

      if (total == 0) {
        value.distribution = [];
        return _extends({}, elem, { value: value });
      }

      var isSentimentBreakdown = properties.kpi === "sentiment_breakdown";
      var splittedSentiments = tableParams.filters.sentiments ? tableParams.filters.sentiments[0][0].split(',') : [];

      computedBreakdown = Object.keys(distribution).map(function (sectionKey, idx) {
        var sectionTotal = distribution[sectionKey];
        var sectionPercentage = sectionTotal / total * 100;
        var color = '';
        var title = '';

        if (properties.kpi && properties.kpi.includes('sentiment')) {
          title = tableParams.translations[currentLanguage].sentiments[sectionKey].title;
        } else if (properties.kpi && properties.kpi.includes('breakdown')) {
          title = sectionKey;
        } else if (properties.kpi && !properties.kpi.includes('sentiment') && properties.segment_id) {
          title = tableParams.translations[currentLanguage]['distribution_' + properties.segment_id][idx].title;
        } else {
          title = tableParams.translations[currentLanguage].distribution[idx].title;
        }

        var color_e = null;
        if (tableParams.view_type !== 'segments' && properties.kpi && properties.kpi.includes('sentiment')) {
          color_e = properties.distribution[sectionKey];
        } else if (properties.segment_id && tableParams['distribution_' + properties.segment_id]) {
          color_e = tableParams['distribution_' + properties.segment_id][idx];
        } else {
          color_e = properties.distribution[idx % properties.distribution.length];
        }
        color = color_e ? color_e.color : '#e6e6e6'; // default color for missing distribution entries

        if (isSentimentBreakdown && splittedSentiments.length > 0 && !splittedSentiments.includes(sectionKey)) {
          color = '#E1E8EE';
        }

        return {
          color: color,
          percentage: Math.round(sectionPercentage),
          style: 'width: ' + sectionPercentage + '%; background-color: ' + color + ';',
          title: title,
          total: sectionTotal || 0
        };
      });

      value.distribution = computedBreakdown;
      return _extends({}, elem, { value: value });
    },

    _getTagBreakdownContent: function _getTagBreakdownContent(content, tableParams, currentLanguage, i18n) {
      var _this3 = this;

      var serializedTags = [];
      // Each line, fill tags name column (config)
      content.forEach(function (line, lineIdx) {

        serializedTags.push([]);
        line.forEach(function (elem, idx) {
          if (idx === 0) {
            serializedTags[lineIdx].push(_extends({}, elem, { main_column: true }));
          } else {
            if (elem.type == 'breakdown') {
              var breakdown = _this3._formatBreakdown(elem, tableParams.properties[idx - 1], tableParams, currentLanguage);
              breakdown.value.title = tableParams.translations[currentLanguage].headers[idx];
              serializedTags[lineIdx].push(breakdown);
            } else if (elem.type == 'number-trend') {
              serializedTags[lineIdx].push(_this3._formatNumberTrend(elem, tableParams.properties[idx - 1], tableParams, i18n));
            } else if (['positive-impact', 'negative-impact'].includes(elem.type)) {
              serializedTags[lineIdx].push(_extends({}, elem, { positiveImpactHideValue: tableParams.positiveImpactHideValue, negativeImpactHideValue: tableParams.negativeImpactHideValue }));
            } else {
              serializedTags[lineIdx].push(_extends({}, elem, { kpi: tableParams.properties[idx - 1].kpi, attrs: tableParams.properties[idx - 1].attrs }));
            }
          }
        });
      });
      return serializedTags;
    },

    _getSummaryOptions: function _getSummaryOptions(tableParams, summaryParams) {
      return {
        config: tableParams.component.config.summary_button,
        generateSummaryAction: summaryParams.generateSummaryAction,
        canUseSummaries: summaryParams.canUseSummaries
      };
    },

    formatData: function formatData(params) {
      var tableParams = params.tableParams;
      var data = params.data;
      var currentLanguage = params.currentLanguage;
      var i18n = params.i18n;
      var summaryParams = params.summaryParams;

      return {
        headers: this.getHeaders(tableParams, currentLanguage || 'EN'),
        rows: this.getRows(data, tableParams, currentLanguage || 'EN', i18n),
        filters: tableParams.filters,
        navigation_button: tableParams.component.config.navigation_button,
        summary_button: this._getSummaryOptions(tableParams, summaryParams)
      };
    }
  });
});