define('due-dashboard/components/due-table/elements/-variation-percent-up', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var htmlSafe = _ember['default'].String.htmlSafe;
  exports['default'] = Component.extend({
    tagName: 'div',
    classNames: ['report-array-number-trend', 'variation-percent-up'],

    upColor: 'green',

    displayGreen: false,
    displayRed: false,

    compValue: computed('value', function () {
      var value = this.get('value.variation');
      var sign = '+';
      if (value == undefined) {
        return '-';
      } else if (value > 0) {
        this.get('upColor') == 'green' ? this.set('displayGreen', true) : this.set('displayRed', true);
      } else if (value < 0) {
        sign = '';
        this.get('upColor') == 'green' ? this.set('displayRed', true) : this.set('displayGreen', true);
      }
      return '' + sign + value + '%';
    })

  });
});