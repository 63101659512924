define('due-dashboard/routes/companies/surveys/index', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({

    limit: 10,

    activate: function activate() {
      this.controllerFor('companies').set('displayFilters', false);
    },

    deactivate: function deactivate() {
      this.controllerFor('companies').set('displayFilters', true);
    },

    model: function model() {
      var account = this.get('currentAccount.content');
      var status = this.controller && this.controller.filterStatus || 'live';
      var survey_type = "diduenjoy";
      var page = { number: this.controller && this.controller.get('currentPage') || 1, size: this.get('limit') };
      return _ember['default'].RSVP.hash({
        account: account,
        surveys: this.store.query('survey', { include: 'active-revision', filter: { status: status, survey_type: survey_type }, sort: '-is_favorite,-created_at', page: page })
      });
    },

    actions: {

      newSurvey: function newSurvey() {
        var _this = this;

        this.controller.set('newSurveyPromise', this.store.createRecord('survey', {
          company: this.get('currentAccount.content.company'),
          survey_type: 'diduenjoy'
        }).save().then(function (survey) {
          return _this.transitionTo('companies.surveys.edit.main', survey.id);
        }));
      }

    }
  });
});